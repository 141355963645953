import React from "react";
import { useQueryClient } from "@tanstack/react-query";

import { useUser } from "../contexts/user";
import { QUERY_KEYS } from "../constants";

export default function useProfile() {
  const queryClient = useQueryClient();
  const { user, updateUser } = useUser();
  const hasSelectedProfile = !!user?.selectedClassroomId;

  const selectedProfile = user?.classrooms?.find(
    (profile) => profile.id === user?.selectedClassroomId,
  );

  const selectProfile = React.useCallback(
    (profileId, options = {}) => {
      const { onSuccess = () => {}, ...rest } = options;
      updateUser(
        { selectedClassroomId: profileId },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.FAVORITES],
            });
            onSuccess();
          },
          ...rest,
        },
      );
    },
    [updateUser, queryClient],
  );

  return {
    profile: selectedProfile,
    hasSelectedProfile,
    selectProfile,
  };
}
