/**
 * Retrieve the cookies from a request object
 * @param {Object} req - A request object.
 * @returns {String} The cookies from the request headers.
 */
export const getReqCookies = (req) => req && req.headers && req.headers.cookie;

/**
 * Sets cookie headers from the API response on the res and req objects for the Node.js server
 * @param {Object} req - A request object.
 * @param {Object} res - A response object.
 * @returns {(resp: Object) => Object}
 */
export const setCookies =
  ({ req, res }) =>
  (resp) => {
    const cookieHeader = resp.headers && resp.headers.get("set-cookie");

    // Pass set cookie requests from the API through Node.js server to the browser
    if (res && res.setHeader && cookieHeader) {
      res.setHeader("Set-Cookie", cookieHeader);
    } else if (res && res.set && cookieHeader) {
      res.set("Set-Cookie", cookieHeader);
    }

    // Set cookies received from the API onto Node.js request object so that
    // chained request have access to the latest cookies
    if (req && req.headers && !req.headers.set && cookieHeader) {
      req.headers.cookie = cookieHeader;
    } else if (req && req.headers && req.headers.set && cookieHeader) {
      req.headers.set("cookie", cookieHeader);
    }

    return resp;
  };
