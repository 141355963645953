import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Pressable from "../Pressable";

const variants = {
  light:
    "text-black bg-white border border-transparent hover:text-pink focus:border-pink-300 focus:ring-pink-300 focus:text-black",
  lightOutline:
    "text-white bg-transparent border border-white hover:text-mint hover:border-mint focus:border-mint-300 focus:ring-mint-300 focus:text-white",
  dark:
    "text-white bg-gray-800 border border-transparent hover:bg-purple focus:ring-purple-300 focus:border-purple-300 focus:bg-purple",
  darkOutline:
    "text-gray-900 bg-transparent border border-gray-900 hover:border-purple focus:ring-purple-300 focus:border-purple-300",
  vivid:
    "text-white bg-purple border border-transparent hover:bg-purple-400 focus:bg-purple focus:border-purple-300 focus:ring-purple-300 focus:ring shadow-[0_4px_0_0_rgba(73,22,169,1),0_8px_0_0_rgba(0,0,0,0.25)]",
  link:
    "text-black underline border border-transparent hover:text-pink focus:border-pink-300 focus:ring-pink-300 focus:text-black",
  golden:
    "text-black bg-orange-950 border border-transparent hover:bg-orange-900 hover:shadow-[0px_8px_0px_0px_rgba(244,186,64,0.25),0px_4px_0px_0px_#F6C860] focus:border-orange-700 focus:shadow-[0px_8px_0px_0px_rgba(244,186,64,0.25),0px_4px_0px_0px_#F6C860]",
};

const sizes = {
  sm: "text-xs h-button-sm px-3",
  md: "text-sm h-button-md px-3",
  lg: "text-md font-bold h-button-lg px-3",
  xl: "text-md font-bold h-button-xl px-6",
};

export const BUTTON_VARIANTS = Object.keys(variants).reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {});

export const BUTTON_SIZES = Object.keys(sizes).reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {});

const Button = React.forwardRef(function Button(
  {
    children,
    variant,
    size,
    disabled = false,
    className,
    type = "button",
    testId,
    onPress,
    ...delegated
  },
  forwardedRef,
) {
  return (
    <Pressable
      ref={forwardedRef}
      className={twMerge(
        "inline-flex items-center justify-center select-none rounded transition-colors focus:outline-none focus:ring",
        variants[variant],
        sizes[size],
        disabled && "opacity-50",
        className,
      )}
      disabled={disabled}
      elementType="button"
      buttonType={type}
      testId={testId}
      onPress={onPress}
      {...delegated}
    >
      {children}
    </Pressable>
  );
});

export default Button;

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit"]),
  testId: PropTypes.string,
  onPress: PropTypes.func,
};
