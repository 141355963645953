import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function useSchoolsFilterQuery(schoolName, options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: schools,
    isLoading: isSchoolsLoading,
    isError: schoolsError,
  } = useQuery({
    queryKey: [QUERY_KEYS.SCHOOLS, schoolName],
    queryFn: () => client.getSchools(schoolName),
    staleTime: oneHour * 24,
    enabled: !!schoolName,
    ...options,
  });

  return { schools, schoolsError, isSchoolsLoading };
}
