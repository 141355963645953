"use client";

/* eslint-disable @next/next/no-img-element */
import React from "react";
import PropTypes from "prop-types";
import NextLink from "next/link";

import MuxPlayer from "./MuxPlayer";
import JWPlayer from "./JWPlayer";
import { URLS } from "../../constants";
import BugsnagClient from "../../utils/bugsnag";

export const PlayerContext = React.createContext();
PlayerContext.displayName = "PlayerContext";

const PLAYERS = {
  MUX: "mux",
  JW: "jw",
};

export default function Player({
  playbackId,
  playlistId,
  adTagUrl,
  autoPlay = false,
  children = null,
  fallback,
  stretching,
  hideControls,
  onPlay,
  onPause,
  onComplete,
  onPlayAttempt,
  onFullscreenChange,
  onTimeUpdate,
  onAdStart,
  onAdComplete,
  onError,
}) {
  const [isPlayerReady, setIsPlayerReady] = React.useState(false);
  const [errorSource, setErrorSource] = React.useState();
  const innerPlayerRef = React.useRef(null);
  const ActivePlayer =
    fallback && errorSource === PLAYERS.JW ? MuxPlayer : JWPlayer;
  const activePlaybackId = !errorSource ? playbackId : fallback?.playbackId;

  const onPlayerReady = React.useCallback(() => {
    setIsPlayerReady(true);
  }, []);

  const value = React.useMemo(
    () => ({
      play: () => innerPlayerRef.current?.play(),
      pause: () => innerPlayerRef.current?.pause(),
      getState: () => innerPlayerRef.current?.getState(),
      getIsAutoPlayed: () => innerPlayerRef.current?.getIsAutoPlayed(),
      onPlay: (callback) => innerPlayerRef.current?.onPlay(callback),
      onPause: (callback) => innerPlayerRef.current?.onPause(callback),
      onComplete: (callback) => innerPlayerRef.current?.onComplete(callback),
      onTimeUpdate: (callback) =>
        innerPlayerRef.current?.onTimeUpdate(callback),
      playerType: ActivePlayer === JWPlayer ? PLAYERS.JW : PLAYERS.MUX,
    }),
    [ActivePlayer],
  );

  if (
    errorSource === PLAYERS.MUX ||
    (errorSource === PLAYERS.JW && !fallback)
  ) {
    return (
      <div className="aspect-w-16 aspect-h-9">
        <div className="bg-white flex flex-col items-center justify-center space-y-4 p-4">
          <h1 className="text-md md:text-lg font-bold text-center">
            The Video Player Is Not Loading
          </h1>

          <h2 className="font-semibold text-sm md:text-md text-center">
            Please check with your school IT administrator{" "}
            <NextLink
              className="underline"
              href="https://support.gonoodle.com/article/361-gonoodle-technical-requirements"
              target="_blank"
            >
              to ensure GoNoodle is allowed
            </NextLink>{" "}
            on your school network or is not blocked by your home network.
          </h2>

          <div className="hidden md:block overflow-hidden">
            <img
              height="347"
              width="912"
              src={`${URLS.GN_ASSETS_BASE_URL}/web_public/images/Zapp_Von_Doubler_Broken.png`}
              alt="Zapp Von Doubler had an accident"
              className="max-w-full max-h-full object-contain"
            />
          </div>

          <div className="text-sm md:text-md text-center">
            Still having trouble? Contact{" "}
            <NextLink
              className="font-bold underline"
              href="mailto:support@gonoodle.com"
            >
              support@gonoodle.com.
            </NextLink>
          </div>
        </div>
      </div>
    );
  }

  return (
    <PlayerContext.Provider value={value}>
      <ActivePlayer
        ref={innerPlayerRef}
        playbackId={activePlaybackId}
        playlistId={playlistId}
        adTagUrl={adTagUrl}
        autoPlay={autoPlay}
        stretching={stretching}
        hideControls={hideControls}
        onPlay={onPlay}
        onPause={onPause}
        onComplete={onComplete}
        onPlayAttempt={onPlayAttempt}
        onFullscreenChange={onFullscreenChange}
        onTimeUpdate={onTimeUpdate}
        onPlayerReady={onPlayerReady}
        onAdStart={onAdStart}
        onAdComplete={onAdComplete}
        onError={(error = {}) => {
          const playerWithError =
            ActivePlayer === JWPlayer ? PLAYERS.JW : PLAYERS.MUX;
          const errorMessage =
            ActivePlayer === JWPlayer
              ? `${error.message} with code ${error.code} and type ${error.type}`
              : `${error.detail?.message} with code ${error.detail?.code}`;

          setErrorSource(playerWithError);

          if (typeof onError === "function") {
            onError(error);
          }

          try {
            BugsnagClient.notify({
              name: `${playerWithError} player errored for video ${activePlaybackId}`,
              message: errorMessage,
            });
          } catch (e) {
            // We don't want to crash the page if error reporting fails.
          }
        }}
      >
        {!isPlayerReady
          ? null
          : typeof children === "function"
          ? children()
          : children}
      </ActivePlayer>
    </PlayerContext.Provider>
  );
}

Player.propTypes = {
  playbackId: PropTypes.string.isRequired,
  playlistId: PropTypes.string,
  adTagUrl: PropTypes.string,
  autoPlay: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  fallback: PropTypes.shape({
    playbackId: PropTypes.string.isRequired,
  }),
  stretching: PropTypes.oneOf(["uniform", "exactfit", "fill", "none"]),
  hideControls: PropTypes.bool,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onComplete: PropTypes.func,
  onPlayAttempt: PropTypes.func,
  onFullscreenChange: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  onAdStart: PropTypes.func,
  onAdComplete: PropTypes.func,
  onError: PropTypes.func,
};
