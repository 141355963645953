import { useQueryClient, useMutation } from "@tanstack/react-query";

import { QUERY_KEYS, MUTATION_KEYS } from "../constants";
import api from "../api";
import useFavoritesQuery from "./useFavoritesQuery";

const client = api();

export default function useFavoriteMutation(slug, contentType, options = {}) {
  const queryClient = useQueryClient();
  const { favorites } = useFavoritesQuery();
  const { onSuccess = () => {}, ...delegated } = options;

  const {
    mutate: toggleFavoriteState,
    isPending: isFavoriteMutationLoading,
    isError: favoriteMutationError,
  } = useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_FAVORITE, slug, contentType],
    mutationFn: () => {
      const isFavorited = favorites.find(
        ({ activity, type: activityType }) =>
          activity.id === slug && activityType === contentType,
      );

      if (isFavorited) {
        return client.unfavorite({ type: contentType, id: slug });
      }

      return client.favorite({ type: contentType, id: slug });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FAVORITES] });
      onSuccess();
    },
    ...delegated,
  });

  return {
    toggleFavoriteState,
    isFavoriteMutationLoading,
    favoriteMutationError,
  };
}
