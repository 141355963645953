import React from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";

import { selectData } from "./selectData";
import { classNames } from "../../../utils/classnames";

export default function Select({
  options = selectData,
  onChange = () => {},
  onBlur = () => {},
  name,
  label,
  helpText,
  disabled,
  error,
  inputProps = {},
  mode = "light",
  value = {},
  leadingText,
  buttonClassNames,
  focusRingClassNames,
  optionClassNames,
  optionTextClassNames,
  checkmark = false,
  testId,
}) {
  const { endAdornment } = inputProps;

  const hasError = Boolean(error);
  const hasLabel = Boolean(label);
  const hasAdornment = Boolean(endAdornment);
  const hasHelpText = Boolean(helpText);
  const isDarkMode = Boolean(mode === "dark");
  const hasLeadingText = Boolean(leadingText);
  const hasCheckmark = Boolean(checkmark);

  const hasButtonClassNames =
    buttonClassNames ||
    "gn-text border-sm h-input border-gray-300 pl-sm shadow-lightSM focus:border-none";
  const hasFocusRingClassNames =
    focusRingClassNames || "focus:ring focus:ring-purple";
  const hasOptionClassNames = optionClassNames || "pl-sm";
  const hasOptionTextSize = optionTextClassNames || "gn-text-sm";

  return (
    <Listbox value={value} onChange={onChange} disabled={disabled} name={name}>
      {({ open }) => (
        <div>
          <label
            htmlFor={name}
            className={`
            ${
              hasLabel
                ? `mb-xs block gn-text-sm ${
                    isDarkMode ? "text-white" : "text-gray-500"
                  }`
                : "sr-only"
            }
          `}
          >
            {label}
          </label>
          <div className="relative">
            <Listbox.Button
              data-testid={testId}
              onBlur={onBlur}
              className={`relative w-full rounded focus:outline-none ${hasButtonClassNames} 
            ${
              hasError
                ? "pr-lg text-danger border-none ring ring-danger focus:ring focus:ring-danger"
                : `${
                    isDarkMode ? "text-white" : "text-gray-900"
                  } ${hasFocusRingClassNames}`
            } cursor-default text-left`}
            >
              {hasLeadingText ? (
                <span data-testid="leading-text">{leadingText}</span>
              ) : null}
              <span className="truncate">{value.name}</span>
              {hasAdornment ? (
                <div
                  className="absolute inset-y-0 right-sm flex items-center"
                  data-testid="end-adornment"
                >
                  {endAdornment}
                </div>
              ) : null}
            </Listbox.Button>

            {hasHelpText ? <Select.HelpText helpText={helpText} /> : null}
            {hasError ? (
              <div
                className="mt-xs flex flex-row gap-xs"
                data-testid="error-text"
              >
                <ExclamationCircleIcon
                  className="h-input-icon text-danger"
                  aria-hidden="true"
                />
                <div className="gn-text-sm text-danger">{error}</div>
              </div>
            ) : null}

            <Transition
              show={open}
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className={`${hasOptionTextSize} absolute z-10 mt-xs w-full bg-white shadow-lg max-h-60 rounded-sm py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}
                data-testid="options-menu"
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-purple" : "text-gray-900",
                        `cursor-default select-none relative py-sm ${hasOptionClassNames} pr-sm`,
                      )
                    }
                    value={option}
                    data-testid="options-item"
                  >
                    {({ selected: ss, active }) => (
                      <>
                        <span className="block truncate">{option.name}</span>

                        {ss ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 left-0 flex items-center pl-1.5",
                            )}
                          >
                            {hasCheckmark ? (
                              <CheckIcon
                                data-testid="check-icon-test"
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : null}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}

function HelpText({ helpText, isDarkMode = false }) {
  return (
    <div
      className={`mt-xs gn-text-sm ${
        isDarkMode ? "text-white" : "text-gray-900"
      }`}
      data-testid="helptext-test"
    >
      {helpText}
    </div>
  );
}

Select.HelpText = HelpText;

HelpText.propTypes = {
  helpText: PropTypes.element,
  isDarkMode: PropTypes.bool,
};

Select.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  label: PropTypes.string,
  helpText: PropTypes.element,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  inputProps: PropTypes.shape({
    endAdornment: PropTypes.element,
  }),
  leadingText: PropTypes.string,
  buttonClassNames: PropTypes.string,
  focusRingClassNames: PropTypes.string,
  optionClassNames: PropTypes.string,
  optionTextClassNames: PropTypes.string,
  checkmark: PropTypes.bool,
  mode: PropTypes.oneOf(["dark", "light"]),
  value: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  testId: PropTypes.string,
};
