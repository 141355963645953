"use client";

import React from "react";
import PropTypes from "prop-types";

const SET_SPLASH_SCREEN_VISIBLE = "SET_SPLASH_SCREEN_VISIBLE";
const SET_LOGOUT_TOAST = "SET_LOGOUT_TOAST";
const SET_TAB_COUNT = "SET_TAB_COUNT";
const TAB_COUNT_KEY = "TAB_COUNT_V2";
const DEPRECATED_TAB_COUNT_KEY = "TAB_COUNT";

const missingAppConfigProvider =
  "You forgot to wrap your app in <AppConfigProvider>";
const AppConfigContext = React.createContext({
  get appConfig() {
    throw new Error(missingAppConfigProvider);
  },
  get changeAppConfig() {
    throw new Error(missingAppConfigProvider);
  },
});

AppConfigContext.displayName = "AppConfigContext";

const initialState = {
  adProductSplashScreen: {
    seen: false,
    visible: false,
  },
  toasts: {
    logout: false,
  },
  tabCount: 0,
};

function reducer(state, action) {
  switch (action.type) {
    case SET_SPLASH_SCREEN_VISIBLE:
      return {
        ...state,
        adProductSplashScreen: {
          seen: !action.payload,
          visible: action.payload,
        },
      };

    case SET_LOGOUT_TOAST:
      return {
        ...state,
        toasts: {
          ...state.toasts,
          logout: action.payload,
        },
      };

    case SET_TAB_COUNT:
      return { ...state, tabCount: action.payload };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default function Provider({ children }) {
  const [appConfig, changeAppConfig] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    // Delete the deprecated tab count key if it exists
    const removeDeprecatedKey = () => {
      if (localStorage.getItem(DEPRECATED_TAB_COUNT_KEY)) {
        localStorage.removeItem(DEPRECATED_TAB_COUNT_KEY);
      }
    };

    const incrementTabCount = () => {
      const currentCount =
        parseInt(localStorage.getItem(TAB_COUNT_KEY), 10) || 0;
      localStorage.setItem(TAB_COUNT_KEY, currentCount + 1);
      changeAppConfig({
        type: SET_TAB_COUNT,
        payload: currentCount + 1,
      });
    };

    const decrementTabCount = () => {
      const currentCount = Math.max(
        (parseInt(localStorage.getItem(TAB_COUNT_KEY), 10) || 1) - 1,
        0,
      );

      localStorage.setItem(TAB_COUNT_KEY, currentCount);

      changeAppConfig({
        type: SET_TAB_COUNT,
        payload: currentCount,
      });
    };

    const handleStorageChange = (event) => {
      if (event.key === TAB_COUNT_KEY) {
        changeAppConfig({
          type: SET_TAB_COUNT,
          payload: parseInt(event.newValue, 10),
        });
      }
    };

    incrementTabCount();
    removeDeprecatedKey();

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("beforeunload", decrementTabCount);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("beforeunload", decrementTabCount);
    };
  }, []);

  const setSplashScreenVisibility = React.useCallback((isVisible) => {
    changeAppConfig({
      type: SET_SPLASH_SCREEN_VISIBLE,
      payload: isVisible,
    });
  }, []);

  const setLogoutToastVisibility = React.useCallback((isVisible) => {
    changeAppConfig({
      type: SET_LOGOUT_TOAST,
      payload: isVisible,
    });
  }, []);

  return (
    <AppConfigContext.Provider
      value={{ appConfig, setSplashScreenVisibility, setLogoutToastVisibility }}
    >
      {children}
    </AppConfigContext.Provider>
  );
}

function useAppConfig() {
  const { appConfig, setSplashScreenVisibility, setLogoutToastVisibility } =
    React.useContext(AppConfigContext);

  return { appConfig, setSplashScreenVisibility, setLogoutToastVisibility };
}

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { useAppConfig };
