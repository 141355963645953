"use client";

import React from "react";
import PropTypes from "prop-types";
import { usePathname, useParams } from "next/navigation";
import { omitBy, isUndefined } from "lodash";
import { useSessionstorageState } from "rooks";

import { getPageAndPageType } from "../../helpers";

const missingReferrerProvider =
  "You forgot to wrap your app in <ReferrerProvider>";

const ReferrerContext = React.createContext({
  get referrer() {
    throw new Error(missingReferrerProvider);
  },
  get setReferrer() {
    throw new Error(missingReferrerProvider);
  },
});

ReferrerContext.displayName = "ReferrerContext";

export default function Provider({ children }) {
  const pathname = usePathname();
  const params = useParams();
  const [referrer, setReferrer] = useSessionstorageState("referrer", {});

  const updateReferrer = React.useCallback(
    (referrerData = {}) => {
      setReferrer(
        omitBy(
          {
            ...getPageAndPageType(pathname, params),
            ...referrerData,
          },
          isUndefined,
        ),
      );
    },
    [setReferrer, pathname, params],
  );

  return (
    <ReferrerContext.Provider value={{ referrer, setReferrer: updateReferrer }}>
      {children}
    </ReferrerContext.Provider>
  );
}

export const useReferrer = () => {
  const { referrer, setReferrer } = React.useContext(ReferrerContext);

  return {
    referrer,
    setReferrer,
  };
};

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
