import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useUserAccessesQuery(options = {}) {
  const {
    data: accessData,
    isLoading: isUserAccessesLoading,
    isError: userAccessesError,
  } = useQuery({
    queryKey: [QUERY_KEYS.USER_ACCESSES],
    queryFn: client.getUserAccesses,
    ...options,
  });

  return {
    userAccesses: accessData?.accesses || [],
    isUserAccessesLoading,
    userAccessesError,
  };
}
