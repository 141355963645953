import React from "react";
import PropTypes from "prop-types";

import Loader from "../components/SkeletonLoader/Loader";

const Columns = React.forwardRef(
  (
    {
      title,
      cols = 4,
      bgColor = "bg-gray-900",
      loading = false,
      children,
      size = "short",
      testID,
    },
    forwardedRef,
  ) => {
    const columnClasses = () => {
      let classes;
      switch (cols) {
        case 2:
          classes = "md:grid-cols-2";
          break;
        case 3:
          classes = "md:grid-cols-2 lg:grid-cols-3";
          break;
        case 4:
          classes = "md:grid-cols-2 lg:grid-cols-4";
          break;
        case 5:
          classes = "grid-cols-2 lg:grid-cols-3 xl:grid-cols-5";
          break;
        default:
          break;
      }
      return classes;
    };
    return (
      <div
        ref={forwardedRef}
        className={`py-sm ${bgColor}`}
        data-testid={testID}
      >
        <div className="container">
          {title && (
            <h2 className="gn-text font-bold text-gray-200 capitalize">
              {title}
            </h2>
          )}
          <div className="pt-sm pb-md">
            <ul
              className={`grid grid-cols-1 gap-x-md gap-y-md ${columnClasses()}`}
            >
              {loading ? <Loader cols={cols} size={size} /> : children}
            </ul>
          </div>
        </div>
      </div>
    );
  },
);

Columns.propTypes = {
  title: PropTypes.string,
  cols: PropTypes.oneOf([1, 2, 3, 4, 5]),
  bgColor: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.string,
  testID: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Columns;
