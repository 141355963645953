import React from "react";
import PropTypes from "prop-types";
import { useCombobox } from "downshift";

import TextField from "../TextField";

export default function Autocomplete({
  options = [],
  selectedOption,
  inputProps = {},
  name,
  label,
  onChange = () => {},
  mapOptionName = (item) => item?.name || "",
  renderOptions,
  onInputValueChange = () => {},
  placeholder,
  error,
  required,
  autoFocus,
  testId,
}) {
  const {
    getComboboxProps,
    getInputProps: getDownshiftInputProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    isOpen,
    highlightedIndex,
  } = useCombobox({
    id: name,
    items: options,
    selectedItem: selectedOption,
    itemToString: mapOptionName,
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: changes.selectedItem
              ? mapOptionName(changes.selectedItem)
              : "",
          };
        default:
          return changes;
      }
    },
    onInputValueChange: ({ inputValue }) => {
      onInputValueChange(inputValue);
    },
    onSelectedItemChange: ({ selectedItem: item }) => {
      onChange(item);
    },
  });

  const {
    value,
    onChange: onInputChange,
    ...downshiftInputProps
  } = getDownshiftInputProps();

  return (
    <div>
      <div className="relative">
        <div {...getComboboxProps()}>
          <TextField
            name={name}
            value={value}
            onInput={onInputChange}
            label={label}
            placeholder={placeholder}
            errorMessage={isOpen ? undefined : error}
            required={required}
            autoFocus={autoFocus}
            testId={testId}
            {...getLabelProps()}
            {...downshiftInputProps}
            {...inputProps}
          />
        </div>
        <ul
          {...getMenuProps()}
          aria-label={label}
          className="absolute overflow-y-scroll max-h-3xl z-10 mt-1 w-full bg-pink-100 shadow-lightMD rounded focus:outline-none"
        >
          {isOpen &&
            options.map((item, index) =>
              renderOptions(item, selectedOption, (config = {}) =>
                getItemProps({
                  key: item.id,
                  className: `${
                    highlightedIndex === index
                      ? "bg-pink-300"
                      : "bg-transparent"
                  } cursor-pointer`,
                  "data-testid": "options-item",
                  index,
                  item,
                  ...config,
                }),
              ),
            )}
        </ul>
      </div>
    </div>
  );
}

Autocomplete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object),
  selectedOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  inputProps: PropTypes.shape({}),
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  mapOptionName: PropTypes.func,
  renderOptions: PropTypes.func.isRequired,
  onInputValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  testId: PropTypes.string,
};
