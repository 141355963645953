export default ({ get, checkStatus, toJSON }) => ({
  getLayout: (id) =>
    get(
      `universe/v2/home_page_blue_prints${
        id ? `?custom_home_page_layout_id=${id}` : ""
      }`,
    )
      .then((response) => checkStatus({ response }))
      .then(toJSON),

  getFallbackLayout: () =>
    get(`universe/v2/home_page_blue_prints/default`)
      .then((response) => checkStatus({ response }))
      .then(toJSON),
});
