import React from "react";
import PropTypes from "prop-types";

function PrivacyPolicyLink({ className }) {
  return (
    <a
      href="https://www.gonoodle.com/company/p/privacy-policy"
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      Privacy Policy
    </a>
  );
}

PrivacyPolicyLink.propTypes = {
  className: PropTypes.string,
};

export default PrivacyPolicyLink;
