import Joi from "joi";

export const champProfileViewed = Joi.object({
  champName: Joi.string().required(),
  sourcePage: Joi.string(),
});

export const champRandomizerUsed = Joi.object({
  champName: Joi.string().required(),
  sourcePage: Joi.string().required(),
  gradeId: Joi.number().allow(null),
  schoolId: Joi.number().allow(null),
  size: Joi.number().allow(null),
  name: Joi.string().allow(null),
});

export const champSelected = Joi.object({
  champName: Joi.string().required(),
  champRandomizerUsed: Joi.boolean().required(),
  sourcePage: Joi.string().required(),
  gradeId: Joi.number().required(),
  schoolId: Joi.number().allow(null),
  size: Joi.number().allow(null),
  name: Joi.string().required(),
  profileType: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const unregisteredChampAchievement = Joi.object({
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const reachChampAchievement = Joi.object({
  champName: Joi.string().required(),
  level: Joi.string().required(),
});

export const champAchievementPrinted = Joi.object({
  champName: Joi.string().required(),
  level: Joi.string().required(),
});

export const newChampSelected = Joi.object({
  champName: Joi.string().required(),
  prevChampName: Joi.string().required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});

export const levelUpVideo = Joi.object({
  newChampLevel: Joi.string().required(),
});

export const levelUpVideoSkipped = Joi.object({
  newChampLevel: Joi.string().required(),
});
