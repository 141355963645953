import React from "react";
import PropTypes from "prop-types";
import { PlayIcon } from "@heroicons/react/outline";

import { ImageSourcesType } from "../Image/types";
import Image from "../Image";
import BackgroundVideo from "../BackgroundVideo";
import CallToActionLabel from "../CallToActionLabel";

export default function HeroVideo({
  title,
  description,
  source,
  heroLogo,
  buttonText = "Play Video",
  mode = "light",
  fallbackImageSource,
  className,
}) {
  return (
    <div className={className}>
      <div
        className="relative flex items-end aspect-w-16 aspect-h-9 px-md rounded-t-lg md:rounded-b-lg overflow-hidden transition-shadow shadow-darkSM hover:shadow-darkLG"
        data-testid="homepageHeroVideo"
      >
        <div className="cursor-pointer" role="button" tabIndex={0}>
          <BackgroundVideo
            source={source}
            fallbackImage={{ sources: fallbackImageSource, alt: title }}
            className="absolute w-full h-full object-cover top-0 right-0 left-0 bottom-0 bg-cover bg-center"
          />
        </div>
        <div
          className={`hidden md:flex absolute bottom-0 right-0 w-full h-full pt-xl items-end justify-between bg-gradient-to-t ${
            mode === "light" ? "from-gray-200" : "from-gray-800"
          } via-transparent`}
        >
          <div
            className={`w-3/4 p-lg ${
              mode === "light" ? "text-gray-900" : "text-white"
            }`}
          >
            <div className="flex flex-col items-start">
              <p
                className="gn-headline-sm mb-xs"
                style={{ textShadow: `3px 3px 3px rgba(0, 0, 0, 0.25)` }}
              >
                {title}
              </p>
              <p
                className="gn-text mb-sm"
                style={{ textShadow: `3px 3px 3px rgba(0, 0, 0, 0.5)` }}
              >
                {description}
              </p>
              <CallToActionLabel
                variant={`${mode === "light" ? "dark" : "light"}`}
                size="sm"
              >
                <span className="flex justify-center items-center font-bold">
                  {buttonText}
                  <PlayIcon className="h-input-icon ml-xs" />
                </span>
              </CallToActionLabel>
            </div>
          </div>
          {heroLogo ? (
            <Image
              className="p-lg pl-xl w-3xl h-auto"
              alt={description}
              sources={heroLogo}
            />
          ) : null}
        </div>
      </div>

      {/* Content for mobile */}
      <div className="flex md:hidden flex-col w-full p-md items-start space-y-sm justify-between bg-gray-800 rounded-b-lg text-white">
        {heroLogo ? (
          <Image
            className="w-auto h-xl pr-lg"
            alt={description}
            sources={heroLogo}
          />
        ) : null}
        <p className="gn-headline-sm font-bold">{title}</p>
        <p className="gn-text-sm mb-md">{description}</p>

        <CallToActionLabel
          variant={`${mode === "light" ? "dark" : "light"}`}
          size="sm"
        >
          <span className="flex justify-center items-center font-bold">
            {buttonText}
            <PlayIcon className="h-input-icon ml-xs" />
          </span>
        </CallToActionLabel>
      </div>
    </div>
  );
}

HeroVideo.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  mode: PropTypes.string,
  description: PropTypes.string.isRequired,
  source: PropTypes.string,
  heroLogo: ImageSourcesType,
  fallbackImageSource: ImageSourcesType.isRequired,
  className: PropTypes.string,
};
