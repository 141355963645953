import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function useContentTagVideosQuery(slug, options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: videos,
    isLoading: isLoadingVideos,
    isError: videosError,
  } = useQuery({
    queryKey: [QUERY_KEYS.CONTENT_TAG_VIDEO_LIST, slug],
    queryFn: () => client.getContentTagVideos(slug),
    staleTime: oneHour * 4,
    ...options,
  });

  return { videos, isLoadingVideos, videosError };
}
