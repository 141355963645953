/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useDateFieldState } from "react-stately";
import { useDateField, useDateSegment, useLocale } from "react-aria";
import { createCalendar } from "@internationalized/date";

export default function DateField({
  value,
  label,
  description,
  errorMessage,
  required = false,
  disabled = false,
  autoFocus,
  onChange = () => {},
  onBlur = () => {},
}) {
  const ref = React.useRef();
  const { locale } = useLocale();
  const state = useDateFieldState({
    locale,
    createCalendar,
    onChange,
    validationState: errorMessage ? "invalid" : "valid",
    isDisabled: disabled,
    isRequired: !!required,
  });
  const {
    labelProps,
    fieldProps,
    errorMessageProps,
    descriptionProps,
  } = useDateField(
    {
      label,
      value,
      isDisabled: disabled,
      isRequired: required,
      errorMessage,
      autoFocus,
      onBlur,
    },
    state,
    ref,
  );

  const hasError = Boolean(errorMessage);

  return (
    <div className="flex flex-col items-start">
      <span {...labelProps} className="mb-xs block gn-text-sm text-gray-500">
        {label}
      </span>
      <div
        {...fieldProps}
        ref={ref}
        className={`inline-flex w-full h-input pl-sm gn-text rounded border-sm border-gray-300 shadow-lightSM items-center
            ${
              hasError
                ? "border-none ring ring-danger focus:ring focus:ring-danger"
                : "focus:ring focus:ring-purple focus:outline-none focus:border-none"
            }
           `}
      >
        {state.segments.map((segment, i) => (
          <DateSegment
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            segment={segment}
            state={state}
            onBlur={onBlur}
          />
        ))}
      </div>

      {description ? (
        <div
          {...descriptionProps}
          className="mt-xs gn-text-sm text-gray-500"
          data-test-id="description-text-container"
        >
          {description}
        </div>
      ) : null}

      <div
        {...errorMessageProps}
        className={`mt-xs flex gap-xs items-center ${
          !hasError ? "hidden" : ""
        }`}
        data-testid="error-text-container"
      >
        <ExclamationCircleIcon
          className="h-input-icon text-danger flex-shrink-0"
          aria-hidden="true"
        />
        <div className="gn-text-sm text-danger">{errorMessage}</div>
      </div>
    </div>
  );
}

function DateSegment({ segment, state }) {
  const ref = React.useRef();
  const { segmentProps } = useDateSegment(segment, state, ref);
  const isValid = state.validationState === "valid";

  return (
    <div
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
      }}
      className={`tabular-nums uppercase text-right outline-none rounded-sm focus:bg-purple focus:text-white group 
      ${isValid && state.value && "text-gray-900"}
      ${isValid && !state.value && "text-gray-400"}
      ${!isValid && "text-danger"}
      `}
    >
      {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
      <span
        aria-hidden="true"
        className={`block w-full text-center group-focus:text-white ${
          isValid ? "text-gray-400" : "text-danger"
        }`}
        style={{
          visibility: segment.isPlaceholder ? "" : "hidden",
          height: segment.isPlaceholder ? "" : 0,
          pointerEvents: "none",
        }}
      >
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? "" : segment.text}
    </div>
  );
}

DateField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
