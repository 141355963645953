import { generatePath } from "@gonoodle/gn-universe-utils";

import {
  ROUTE_PATHS,
  SPOTLIGHT_TYPES,
  ROUTE_PREFIX,
  CONTENT_TYPES,
} from "../constants";

export const generateSpotlightPathByType = (destinationType, params = {}) => {
  const DESTINATION = {
    [SPOTLIGHT_TYPES.VIDEO]: ROUTE_PATHS.VIDEOS,
    [SPOTLIGHT_TYPES.ACTIVITY]: ROUTE_PATHS.ACTIVITIES,
    [SPOTLIGHT_TYPES.TAG]: ROUTE_PATHS.TAGS,
  };

  if (DESTINATION[destinationType]) {
    const path = generatePath(DESTINATION[destinationType], params);
    return path;
  }
  return null;
};

export function getRoutePrefixByContentType(contentType) {
  switch (contentType) {
    case CONTENT_TYPES.VIDEO:
      return `/${ROUTE_PREFIX.VIDEOS}`;
    case CONTENT_TYPES.ACTIVITY:
      return `/${ROUTE_PREFIX.ACTIVITIES}`;
    case CONTENT_TYPES.TOPIC:
      return `/${ROUTE_PREFIX.TAGS}`;
    case CONTENT_TYPES.CHANNEL:
      return `/${ROUTE_PREFIX.TAGS}`;
    case CONTENT_TYPES.TAG:
      return `/${ROUTE_PREFIX.TAGS}`;
    case CONTENT_TYPES.CUSTOM_LINK:
      return ``;
    default:
      return null;
  }
}
