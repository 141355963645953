import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import { ImageSourcesType } from "../Image/types";
import Image from "../Image";
import BackgroundVideo from "../BackgroundVideo";
import CallToActionLabel from "../CallToActionLabel";

const missingHeroUnitProvider = "Component must be used within <HeroUnit.Root>";
const HeroUnitContext = React.createContext({
  get textColor() {
    throw new Error(missingHeroUnitProvider);
  },
});
HeroUnitContext.displayName = "HeroUnitContext";

export function PrimaryCard({
  title,
  description,
  textTheme,
  media,
  sponsorLogo,
  callToAction,
}) {
  const textColor = textTheme === "light" ? "text-white" : "text-gray-900";

  return (
    <div className="w-full aspect-h-9">
      <div className="flex relative aspect-w-16 overflow-hidden rounded-t-lg lg:rounded-lg">
        <div>
          <BackgroundVideo
            className="w-full h-full object-cover"
            source={media.loopingVideo}
            fallbackImage={{
              sources: media.backgroundImage,
              alt: title,
            }}
          />

          <div className="absolute z-10 bottom-2 md:bottom-8 right-2 md:right-8">
            {sponsorLogo && (
              <Image
                className="w-auto h-auto max-w-[80px] sm:max-w-[120px] md:max-w-[150px] max-h-[80px] sm:max-h-[120px] md:max-h-[50px]"
                alt="Sponsor logo"
                sources={sponsorLogo}
              />
            )}
          </div>

          <div
            className={`hidden md:flex items-end absolute bottom-0 inset-x-0 h-full p-8 bg-gradient-to-t 
          ${
            textTheme === "light" ? "from-gray-800" : "from-gray-200"
          } via-transparent`}
          >
            <div className={`flex flex-col gap-2 ${textColor}`}>
              <div>
                <h2
                  className="font-extrabold gn-headline-sm line-clamp-2 mb-xs leading-10 pb-2"
                  style={{ textShadow: `3px 3px 3px rgba(0, 0, 0, 0.5)` }}
                >
                  {title}
                </h2>
                <p
                  className="gn-text mb-sm line-clamp-2"
                  style={{ textShadow: `3px 3px 3px rgba(0, 0, 0, 0.5)` }}
                >
                  {description}
                </p>
              </div>
              <CallToActionLabel
                variant={textTheme}
                size="lg"
                className="w-fit"
              >
                {callToAction}
              </CallToActionLabel>
            </div>
          </div>
        </div>
      </div>

      {/* Start Mobile view */}
      <div className={`flex flex-col md:hidden p-4 ${textColor}`}>
        <div className="mb-4">
          <h2 className="text-lg font-extrabold mb-1">{title}</h2>
          <p className="text-sm">{description}</p>
        </div>

        <CallToActionLabel variant={textTheme} size="lg" className="w-fit">
          {callToAction}
        </CallToActionLabel>
      </div>
      {/* End Mobile view */}
    </div>
  );
}

export function SecondaryCard({ title, description, image }) {
  const { textColor } = React.useContext(HeroUnitContext);
  return (
    <div className="flex flex-row space-x-4 flex-1">
      <div className="rounded-lg overflow-hidden min-w-[90px] max-w-[90px] h-[49px] max-h-[49px] md:min-w-[123px] md:min-h-[70px] md:max-w-[123px] md:max-h-[70px] lg:min-w-[152px] lg:max-w-[152px] lg:min-h-[83px] lg:max-h-[83px] 3xl:min-w-[260px] 3xl:max-w-[260px] 3xl:min-h-[142px] 3xl:max-h-[142px]">
        <Image
          className="w-full h-full object-cover"
          sources={image}
          alt={title}
        />
      </div>
      <div
        className={twMerge(
          "flex flex-col justify-center lg:justify-start",
          textColor,
        )}
      >
        <h3 className="text-base leading-[1.4rem] font-bold line-clamp-2">
          {title}
        </h3>
        <p className="hidden lg:line-clamp-2 3xl:line-clamp-5 text-sm font-light md:mt-1">
          {description}
        </p>
      </div>
    </div>
  );
}

export function CardList({ title, description, children }) {
  const { textColor } = React.useContext(HeroUnitContext);
  return (
    <div
      className={twMerge(
        "flex flex-col w-full gap-1 px-4 pt-4 mb-6 md:gap-6 md:max-lg:px-8 lg:mb-0 lg:pt-0 lg:px-0 lg:w-[46%]",
        React.Children.count(children) === 0 && !title && "hidden",
      )}
    >
      {title && (
        <div className={twMerge("flex flex-col w-full gap-4 mb-sm", textColor)}>
          <h2 className="gn-headline-sm pb-xs font-extrabold line-clamp-2">
            {title}
          </h2>
          <p className="gn-text-sm">{description}</p>
        </div>
      )}
      <div
        className={twMerge(
          "flex flex-col gap-6 md:gap-8 md:max-lg:flex-row md:max-lg:flex-wrap lg:flex-col",
          React.Children.count(children) === 4 &&
            !title &&
            "justify-between h-full",
        )}
      >
        {children}
      </div>
    </div>
  );
}

export function Root({
  textTheme,
  frame = false,
  sponsorLogo,
  className,
  children,
}) {
  const textColor = textTheme === "light" ? "text-white" : "text-gray-900";
  const value = React.useMemo(
    () => ({
      textColor,
    }),
    [textColor],
  );

  return (
    <div
      className={twMerge(
        "flex flex-col lg:flex-row lg:space-x-6 h-full relative",
        frame && "pb-6 lg:px-[60px] lg:py-[48px]",
        className,
      )}
    >
      <HeroUnitContext.Provider value={value}>
        {children}

        {sponsorLogo && (
          <div className="flex flex-1 items-end justify-center mt-7 lg:mt-0 lg:absolute lg:right-8 lg:bottom-3">
            <div className="flex flex-row gap-x-4 items-center justify-center">
              <p className={twMerge("font-bold text-xs", textColor)}>
                Powered by
              </p>
              <Image
                className="w-auto h-auto max-w-[48px] max-h-[24px] lg:max-w-[88px] lg:max-h-[31px]"
                sources={sponsorLogo}
              />
            </div>
          </div>
        )}
      </HeroUnitContext.Provider>
    </div>
  );
}

Root.propTypes = {
  textTheme: PropTypes.string.isRequired,
  sponsorLogo: ImageSourcesType,
  frame: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PrimaryCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  textTheme: PropTypes.string.isRequired,
  media: PropTypes.shape({
    loopingVideo: PropTypes.string,
    backgroundImage: ImageSourcesType,
  }).isRequired,
  sponsorLogo: ImageSourcesType,
  callToAction: PropTypes.node.isRequired,
};

CardList.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

SecondaryCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: ImageSourcesType.isRequired,
};
