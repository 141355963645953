"use client";

import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@tanstack/react-query";
import { validate } from "@gonoodle/gn-universe-analytics-schema";
import { flowRight, omitBy, isEmpty } from "lodash";
import { useDeepCompareEffect } from "@gonoodle/gn-universe-utils";
import { usePathname, useSearchParams, useParams } from "next/navigation";

import BugsnagClient from "../../utils/bugsnag";
import { useReferrer } from "../Referrer";
import api from "../../api";
import { getPageAndPageType } from "../../helpers";

const AnalyticsContext = React.createContext();
AnalyticsContext.displayName = "AnalyticsContext";

const fetcher = api();

function getUtmParamsFromQueryParams(params) {
  return omitBy(
    {
      utmSource: params.get("utm_source"),
      utmMedium: params.get("utm_medium"),
      utmCampaign: params.get("utm_campaign"),
    },
    isEmpty,
  );
}

function reportError({ properties, event, error }) {
  if (error && process.env.NODE_ENV === "development") {
    throw new Error(
      `Invalid ${event} event properties, ${error}. Received: ${JSON.stringify(
        properties,
      )}`,
    );
  } else if (error) {
    BugsnagClient.notify({
      name: "Missing analytics properties",
      message: `${event} event has missing properties, passed properties: ${
        properties ? JSON.stringify(properties) : undefined
      }, error: ${error}`,
    });
  }
  return {
    properties,
    event,
  };
}

export default function Provider({
  children,
  options = {
    doNotTrack: false,
  },
}) {
  return (
    <AnalyticsContext.Provider value={options}>
      {children}
    </AnalyticsContext.Provider>
  );
}

function useAnalytics() {
  const context = React.useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error("You forgot to wrap your app in a AnalyticsProvider");
  }
  return context;
}

function useLogEventBase({ options = {} }) {
  const pathname = usePathname();
  const params = useParams();
  const searchParams = useSearchParams();
  const { doNotTrack } = useAnalytics();
  const { referrer } = useReferrer();
  const {
    mutateAsync: sendEvent,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: ({ event, properties }) =>
      fetcher.trackAnalyticsEvents({
        event,
        properties,
      }),
    retry: 3,
  });

  const logEventWithValidation = flowRight(sendEvent, reportError, validate);
  const {
    includeReferrer = true,
    includeSourcePage = false,
    includeSourcePageType = false,
  } = options;

  const logEventBase = React.useCallback(
    ({ event, properties }) => {
      if (!doNotTrack && event) {
        const urlSourcePage = searchParams.get("source_page");
        const urlSourcePageType = searchParams.get("source_page_type");
        const urlSourceName = searchParams.get("source_name");
        const urlSourceElement = searchParams.get("source_element");

        let sourceProperties = {};

        if (includeReferrer === false) {
          const { sourcePage, sourcePageType } = getPageAndPageType(
            pathname,
            params,
          );

          if (includeSourcePage) {
            sourceProperties.sourcePage = sourcePage;
          }

          if (includeSourcePageType) {
            sourceProperties.sourcePageType = sourcePageType;
          }
        } else if (
          urlSourcePage ||
          urlSourcePageType ||
          urlSourceName ||
          urlSourceElement
        ) {
          sourceProperties = {
            sourcePage: urlSourcePage,
            sourcePageType: urlSourcePageType,
            sourceName: urlSourceName,
            sourceElement: urlSourceElement,
          };
        } else if (isEmpty(referrer)) {
          sourceProperties.sourcePage = "direct";
        } else {
          sourceProperties = referrer;
        }

        logEventWithValidation({
          event,
          properties: {
            ...getUtmParamsFromQueryParams(searchParams),
            ...sourceProperties,
            ...properties,
          },
        });
      }
    },
    [
      doNotTrack,
      includeReferrer,
      includeSourcePage,
      includeSourcePageType,
      logEventWithValidation,
      params,
      pathname,
      referrer,
      searchParams,
    ],
  );

  return {
    logEventBase,
    isSuccess,
    isError,
  };
}

export function useLogEvent({ event, properties = {}, options = {} }) {
  const sent = React.useRef(false);
  const { logEventBase, isSuccess, isError } = useLogEventBase({ options });
  const { enabled = false } = options;

  const logEvent = React.useCallback(
    (extraProperties = {}) =>
      logEventBase({
        event,
        properties: {
          ...properties,
          ...extraProperties,
        },
      }),
    [logEventBase, event, properties],
  );

  React.useEffect(() => {
    if (enabled && sent.current === false) {
      sent.current = true;
      logEvent();
    }
  }, [enabled, logEvent]);

  return {
    logEvent,
    isSuccess,
    isError,
  };
}

export function useLogEvents({ events = [], options = {} }) {
  const sent = React.useRef(false);
  const { logEventBase } = useLogEventBase({ options });
  const { enabled = false, ...delegatedOptions } = options;

  useDeepCompareEffect(() => {
    if (enabled && sent.current === false) {
      sent.current = true;

      events.forEach(({ event, properties = {} }) => {
        logEventBase({ event, properties });
      });
    }
  }, [enabled, events, logEventBase]);

  const logEvents = React.useCallback(() => {
    events.forEach(({ event, properties }) =>
      logEventBase({ event, properties, options: delegatedOptions }),
    );
  }, [delegatedOptions, events, logEventBase]);

  return {
    logEvents,
  };
}

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  options: PropTypes.shape({
    doNotTrack: PropTypes.bool,
  }),
};
