import React from "react";
import PropTypes from "prop-types";

import { useAssetsConfig } from "../../contexts/AssetsConfig";
import Button, { BUTTON_VARIANTS, BUTTON_SIZES } from "../../components/Button";

export default function FourOFour({ onBackPress = () => {} }) {
  const { assetsUrl } = useAssetsConfig();
  const [errorTitle, setErrorTitle] = React.useState("Did you get lost?");
  const [errorMessage, setErrorMessage] = React.useState(
    "The page you are looking for does not exist.",
  );

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get("message");

    if (message === "410") {
      setErrorTitle("This content is no longer available.");
      setErrorMessage(
        "But with hundreds of videos and activities, we're sure we have something you'll love!",
      );
    }
  }, []);

  return (
    <div className="text-center flex flex-col justify-center items-center px-8 pt-8 mx-auto max-w-4xl">
      <h1 className="text-4xl mb-4">404</h1>
      <h2 className="text-2xl mb-4 leading-snug">{errorTitle}</h2>
      <p className="text-lg mb-4">{errorMessage}</p>
      <div className="mb-4">
        <Button
          size={BUTTON_SIZES.xl}
          variant={BUTTON_VARIANTS.dark}
          onPress={onBackPress}
        >
          Back to GoNoodle
        </Button>
      </div>
      <img
        className="object-contain w-[226px] h-[394px]"
        src={`${assetsUrl}/web_public/squatchy-shrug.png`}
        alt="Squatchy could not find the page"
      />
      <div className="mt-4">
        Still having trouble? Contact{" "}
        <a className="font-bold underline" href="mailto:support@gonoodle.com">
          support@gonoodle.com.
        </a>
      </div>
    </div>
  );
}

FourOFour.propTypes = {
  onBackPress: PropTypes.func,
};
