import React from "react";
import PropTypes from "prop-types";

import SkeletonLoader from "./index";

export default function Loader({ cols = 4, size = "short", mode = "dark" }) {
  return (
    <>
      {[...Array(cols)].map((col, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SkeletonLoader key={index} size={size} mode={mode} />
      ))}
    </>
  );
}

Loader.propTypes = {
  cols: PropTypes.number,
  mode: PropTypes.oneOf(["dark", "light"]),
  size: PropTypes.oneOf(["short", "tall"]),
};
