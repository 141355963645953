/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import { useNumberField } from "react-aria";
import { useNumberFieldState } from "react-stately";
import { useComposedRefs } from "@gonoodle/gn-universe-utils";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

const NumberField = React.forwardRef(
  (
    {
      name,
      label,
      placeholder,
      description,
      errorMessage,
      value,
      minValue,
      maxValue,
      required,
      disabled,
      defaultValue,
      onBlur,
      onChange,
    },
    forwardedRef,
  ) => {
    const ref = React.useRef();
    const composedRefs = useComposedRefs(ref, forwardedRef);
    const state = useNumberFieldState({
      name,
      label,
      placeholder,
      description,
      value,
      defaultValue,
      errorMessage,
      isRequired: required,
      isDisabled: disabled,
      locale: "en-US",
      minValue,
      maxValue,
      onChange,
      onBlur,
    });

    const {
      labelProps,
      inputProps,
      descriptionProps,
      errorMessageProps,
    } = useNumberField(
      {
        name,
        label,
        placeholder,
        description,
        value,
        defaultValue,
        onChange,
        minValue,
        maxValue,
        errorMessage,
        isRequired: required,
        isDisabled: disabled,
        onBlur,
      },
      state,
      ref,
    );
    const hasError = Boolean(errorMessage);
    const hasDescription = Boolean(description);

    return (
      <div>
        <label className="mb-xs block gn-text-sm text-gray-500" {...labelProps}>
          {label}
        </label>
        <input
          {...inputProps}
          ref={composedRefs}
          className={`block w-full h-input pl-sm gn-text rounded border-sm border-gray-300 shadow-lightSM
          ${
            hasError
              ? "text-danger placeholder-danger border-none ring ring-danger focus:ring focus:ring-danger"
              : "text-gray-900 placeholder-gray-500 focus:ring focus:ring-purple focus:outline-none focus:border-none"
          }
         `}
        />
        {hasDescription ? (
          <div
            {...descriptionProps}
            className="mt-xs gn-text-sm text-gray-500"
            data-testid="helptext-test"
          >
            {description}
          </div>
        ) : null}

        <div
          {...errorMessageProps}
          className={`mt-xs flex flex-row gap-xs ${!hasError && "hidden"}`}
          data-testid="error-text"
        >
          <ExclamationCircleIcon
            className="h-input-icon text-danger flex-shrink-0"
            aria-hidden="true"
          />
          <div className="gn-text-sm text-danger">{errorMessage}</div>
        </div>
      </div>
    );
  },
);

NumberField.displayName = "NumberField";

NumberField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  errorMessage: PropTypes.string,
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default NumberField;
