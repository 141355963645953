import React from "react";
import PropTypes from "prop-types";
import ImageTile from "../ImageTile";

import { ImageSourcesType } from "../Image/types";

function MediaCard({
  title,
  slug,
  images,
  description,
  handleClick = () => {},
  layout,
}) {
  const isTall = layout === "tall";
  const tileFormat = isTall ? "portrait" : "landscape";
  const imageWidth = isTall ? "w-1/4" : "w-2/5";
  const contentWidth = isTall ? "w-3/4" : "w-3/5";

  return (
    <div className="flex group bg-gray-700 rounded cursor-pointer overflow-hidden">
      <div
        className={`relative group bg-gray-500 group-hover:bg-mint ${imageWidth}`}
        style={{ paddingBottom: `${isTall ? "36%" : "22%"}` }}
      >
        <div className="absolute inset-0">
          <ImageTile
            format={tileFormat}
            sources={images.tile}
            alt={`${slug}-image`}
            onClick={handleClick}
            childComponent
          />
        </div>
        <span className="sr-only">View details for {title}</span>
      </div>
      <div
        data-testid="media-card__content-area"
        className={`${contentWidth} self-center p-md xl:p-lg`}
        tabIndex={0}
        role="link"
        onKeyUp={handleClick}
        onClick={handleClick}
      >
        <p className="gn-text text-white group-hover:text-mint font-bold line-clamp-3">
          {title}
        </p>
        {!isTall ? (
          <p className="gn-text-sm text-white line-clamp-3">{description}</p>
        ) : null}
      </div>
    </div>
  );
}

MediaCard.propTypes = {
  title: PropTypes.string.isRequired,
  layout: PropTypes.oneOf(["tall", "wide"]).isRequired,
  slug: PropTypes.string.isRequired,
  images: PropTypes.shape({ tile: ImageSourcesType }).isRequired,
  /**
   * Shown only for wide MediaCards
   */
  description: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

export default MediaCard;
