import React from "react";
import PropTypes from "prop-types";
import { Switch } from "@gonoodle/gn-universe-ui";

import ContentTag from "../ContentTag";

export default function VideoFooter({
  videoDetails,
  onClick,
  printableMediaCard,
  actions,
}) {
  const [isTranscriptVisible, setIsTranscriptVisible] = React.useState(false);

  return (
    <div className="pb-md pt-sm">
      {actions}

      <div className="flex flex-col lg:flex-row mt-md">
        <div className="w-full lg:w-3/5">
          <div className="gn-text-sm text-white lg:pr-xl mb-md">
            {videoDetails.description}
          </div>
          <div className="mb-md">
            {videoDetails.contentTags.map(({ id, name, slug }) => (
              <ContentTag
                key={id}
                text={name}
                slug={slug}
                onClick={() => onClick(id, slug)}
              />
            ))}
          </div>
        </div>
        {videoDetails.printable ? (
          <div className="w-full lg:w-2/5 mb-md">{printableMediaCard}</div>
        ) : null}
      </div>
      {videoDetails.transcript ? (
        <>
          <div className="flex flex-row w-full">
            <Switch.Root
              checked={isTranscriptVisible}
              onCheckedChange={() => {
                setIsTranscriptVisible((prevState) => !prevState);
              }}
            >
              <div className="inline-flex items-center">
                <Switch.Label className="text-white mr-sm cursor-pointer">
                  Show video transcript
                </Switch.Label>
                <Switch.Thumb />
              </div>
            </Switch.Root>
            <div className="grow" />
          </div>
          <div
            className={
              isTranscriptVisible
                ? `mt-md gn-text-sm text-white block`
                : `hidden`
            }
          >
            {videoDetails.transcript}
          </div>
        </>
      ) : null}
    </div>
  );
}

VideoFooter.propTypes = {
  videoDetails: PropTypes.shape({
    contentTags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    icon: PropTypes.string,
    sponsorGptAdId: PropTypes.string,
    sponsorGptAdRendered: PropTypes.bool,
    sponsorIcon: PropTypes.string,
    partnerUrl: PropTypes.string,
    partnerName: PropTypes.string,
    description: PropTypes.string.isRequired,
    transcript: PropTypes.string,
    title: PropTypes.string.isRequired,
    printable: PropTypes.object,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  printableMediaCard: PropTypes.node,
  actions: PropTypes.node,
};
