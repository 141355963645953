import React from "react";
import PropTypes from "prop-types";
import { DownloadIcon } from "@heroicons/react/outline";

import Image from "../Image";
import ContentTag from "../ContentTag";
import Pressable from "../Pressable";

function PrintableSection({
  description,
  title,
  image,
  contentTags = [],
  onDownload,
}) {
  return (
    <div className="container md:max-w-screen-xl pb-md">
      <div className="flex flex-row flex-wrap md:flex-nowrap">
        <div className="w-full md:w-2/5 md:mr-md">
          <Image className="w-full" sources={image} alt="printable cover" />
        </div>
        <div className="flex flex-col md:mt-xl md:w-3/5">
          <h4 className="gn-page-header-sm text-white pb-md">{title}</h4>
          <p className="gn-text text-white pb-md">{description}</p>
          <div className="pb-sm">
            {contentTags.map(({ id, name, slug }) => (
              <ContentTag key={id} text={name} slug={slug} onClick={() => {}} />
            ))}
          </div>
          <div className="pt-sm pb-lg">
            <Pressable
              className="text-md font-bold h-button-lg px-sm"
              size="lg"
              onPress={onDownload}
            >
              <span>
                Download
                <DownloadIcon className="h-md ml-xs order-last" />
              </span>
            </Pressable>
          </div>
          {/* These width classes control the size of the Media Card */}
          <div className="w-full md:w-5/6">{/* Media Card Goes Here */}</div>
        </div>
      </div>
    </div>
  );
}

PrintableSection.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
  image: PropTypes.shape({
    "regular@1x": PropTypes.string.isRequired,
    "regular@2x": PropTypes.string.isRequired,
    "regular@3x": PropTypes.string.isRequired,
  }).isRequired,
  contentTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
};

export default PrintableSection;
