/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import { useSwitch, VisuallyHidden, useFocusRing } from "react-aria";
import { useToggleState } from "react-stately";

const missingSwitchProvider = "Component must be used within <Switch.Root>";
const SwitchContext = React.createContext({
  get checked() {
    throw new Error(missingSwitchProvider);
  },
  get isFocusVisible() {
    throw new Error(missingSwitchProvider);
  },
});
SwitchContext.displayName = "SwitchContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Root({
  checked = false,
  onCheckedChange = () => {},
  name,
  className,
  children,
  disabled = false,
}) {
  const ref = React.useRef();
  const state = useToggleState({
    isSelected: checked,
    onChange: onCheckedChange,
  });
  const { inputProps } = useSwitch(
    {
      name,
      children,
      isDisabled: disabled,
    },
    state,
    ref,
  );
  const { isFocusVisible, focusProps } = useFocusRing();
  const value = React.useMemo(() => ({ checked, isFocusVisible }), [
    checked,
    isFocusVisible,
  ]);

  return (
    <SwitchContext.Provider value={value}>
      <label className={className}>
        <VisuallyHidden>
          <input {...inputProps} {...focusProps} ref={ref} />
        </VisuallyHidden>
        {children}
      </label>
    </SwitchContext.Provider>
  );
}

export function Label({ children, className }) {
  return (
    <span className={`text-sm font-medium text-gray-900 ${className}`}>
      {children}
    </span>
  );
}

export function Description({ children, className }) {
  return (
    <span className={`text-sm text-gray-500 ${className}`}>{children}</span>
  );
}

export function Thumb() {
  const { checked, isFocusVisible } = React.useContext(SwitchContext);

  return (
    <div
      className={classNames(
        checked ? "bg-purple" : "bg-gray-200",
        isFocusVisible ? "outline-none ring-2 ring-offset-2 ring-purple" : "",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 ",
      )}
    >
      <span
        aria-hidden="true"
        className={classNames(
          checked ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
        )}
      />
    </div>
  );
}

Root.propTypes = {
  onCheckedChange: PropTypes.func,
  checked: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
};

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Description.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
