import { isEmpty } from "lodash";
import { useSearchParams } from "next/navigation";

import useRouter from "../useRouter";

export default function useIntendedPath() {
  const searchParams = useSearchParams();
  const router = useRouter();

  const intendedPath = searchParams.get("intended_path");

  const redirectToIntendedPath = ({ fallbackPath }) => {
    if (!isEmpty(intendedPath)) {
      router.push(intendedPath);
    } else if (fallbackPath) {
      router.push(fallbackPath);
    }
  };

  return {
    intendedPath,
    redirectToIntendedPath,
  };
}
