import React from "react";
import { useRouter as usePrimitiveRouter } from "next/navigation";

import { useReferrer } from "../../contexts/Referrer";

export default function useRouter() {
  const primitiveRouter = usePrimitiveRouter();
  const { setReferrer } = useReferrer();

  const push = React.useCallback(
    (url, options, referrer) => {
      setReferrer(referrer);

      return primitiveRouter.push(url, options);
    },
    [primitiveRouter, setReferrer],
  );

  const replace = React.useCallback(
    (url, options, referrer) => {
      setReferrer(referrer);

      return primitiveRouter.replace(url, options);
    },
    [primitiveRouter, setReferrer],
  );

  return {
    ...primitiveRouter,
    push,
    replace,
  };
}
