import React from "react";
import PropTypes from "prop-types";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useCheckbox } from "react-aria";
import { useToggleState } from "react-stately";
import { twMerge } from "tailwind-merge";

export default function Checkbox({
  name,
  label,
  ariaLabel,
  labelClassName,
  id,
  checked,
  value,
  defaultChecked,
  description,
  required = false,
  disabled = false,
  checkboxClassName,
  wrapperClassName,
  error,
  onChange,
  onBlur,
}) {
  const ref = React.useRef(null);
  const state = useToggleState({
    defaultSelected: defaultChecked,
    isSelected: checked,
    value,
    name,
    onChange,
  });
  const { inputProps } = useCheckbox(
    {
      name,
      "aria-label": ariaLabel || label,
      id,
      "aria-describedby": description ? `${name}-description` : undefined,
      isSelected: checked,
      value,
      defaultSelected: defaultChecked,
      isRequired: required,
      isDisabled: disabled,
      "aria-errormessage": error ? `${name}-error` : undefined,
      validationState: error ? "invalid" : "valid",
      onChange,
      onBlur,
    },
    state,
    ref,
  );

  return (
    <div className={twMerge("relative flex items-start", wrapperClassName)}>
      <div className="flex items-center h-5">
        <input
          ref={ref}
          className={twMerge(
            error
              ? "border-none ring ring-danger focus:ring focus:ring-danger rounded"
              : "focus:ring focus:ring-purple focus:outline-none focus:border-none h-4 w-4 text-purple border-gray-300 rounded",
            checkboxClassName,
          )}
          {...inputProps}
        />
      </div>

      <div className="ml-2 text-sm">
        <label
          htmlFor={name}
          className={twMerge("text-gray-700", labelClassName)}
        >
          {label}
        </label>

        {description ? (
          <p id={inputProps["aria-describedby "]} className="text-gray-500">
            {description}
          </p>
        ) : null}

        {error ? (
          <div className="mt-xs flex flex-row gap-xs">
            <ExclamationCircleIcon
              className="h-input-icon text-danger"
              aria-hidden="true"
            />
            <div
              id={inputProps["aria-errormessage"]}
              className="gn-text-sm text-danger"
            >
              {error}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

Checkbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultChecked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelClassName: PropTypes.string,
  ariaLabel: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  checkboxClassName: PropTypes.string,
  error: PropTypes.string,
  checked: PropTypes.bool,
  description: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
