import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../constants";
import api from "../api";
import { useUser } from "../contexts/user";

const fetcher = api();

export default function useCompetenciesCompletionsQuery(
  competencies = [],
  options = {},
) {
  const { user } = useUser();

  const {
    data: competenciesCompletions,
    isLoading: isCompetenciesCompletionsLoading,
    isError: competenciesCompletionsError,
  } = useQuery({
    queryKey: [QUERY_KEYS.COMPETENCIES_COMPLETIONS, ...competencies],
    queryFn: () => fetcher.getCompetenciesCompletions(competencies),
    enabled:
      competencies.length > 0 && user.features.useDeprecatedCurriculum !== true,
    refetchOnWindowFocus: false,
    ...options,
  });

  return {
    competenciesCompletions,
    isCompetenciesCompletionsLoading,
    competenciesCompletionsError,
  };
}
