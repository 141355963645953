import { useQuery } from "@tanstack/react-query";
import { filter } from "lodash";

import { useUser } from "../contexts/user";
import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useFavoritesQuery(predicate, options = {}) {
  const { user } = useUser();
  const oneHour = 1000 * 60 * 60;

  const {
    data: favorites,
    isLoading: isFavoritesLoading,
    isError: favoritesError,
  } = useQuery({
    queryKey: [QUERY_KEYS.FAVORITES],
    queryFn: client.getFavorites,
    enabled: user.isLoggedIn,
    select: (data) => filter(data, predicate),
    staleTime: oneHour * 4,
    ...options,
  });

  return { favorites, isFavoritesLoading, favoritesError };
}
