import { useQueryClient, useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import { useUser } from "../contexts/user";
import api from "../api";

const fetcher = api();

// TODO - In a future we should remove this hook when the old on-site messages are removed
export default function useOnSiteMessagesMutation(
  { contentType, contentId },
  options = {},
) {
  const queryClient = useQueryClient();
  const { user: userData } = useUser();
  const { v2OnSiteMessagesOn } = userData.features;

  const {
    mutate: updateMessagesStatus,
    isPending: isUpdateMessagesMutationLoading,
    isError: updateMessagesMutationError,
  } = useMutation({
    mutationFn: (messagesStatus) =>
      fetcher.updateOnSiteMessageStatus(messagesStatus),
    onMutate: async (messagesStatus) => {
      // Cancel any outgoing refetches
      if (v2OnSiteMessagesOn) {
        queryClient.cancelQueries({
          queryKey: [QUERY_KEYS.ON_SITE_MESSAGES, contentType, contentId],
        });

        // Optimistically update to the new value
        queryClient.setQueryData(
          [QUERY_KEYS.ON_SITE_MESSAGES, contentType, contentId],
          (onSiteMessages) =>
            onSiteMessages.map((message) => {
              const foundMessage = messagesStatus.find(
                ({ onSiteMessageId }) =>
                  onSiteMessageId === message.onSiteMessageId,
              );
              return foundMessage
                ? {
                    ...message,
                    status: foundMessage.status,
                  }
                : message;
            }),
        );
      } else {
        queryClient.cancelQueries({
          queryKey: [QUERY_KEYS.USER],
        });

        // Optimistically update to the new value
        queryClient.setQueryData([QUERY_KEYS.USER], (user) => ({
          ...user,
          onSiteMessages: user.onSiteMessages.map((message) => {
            const foundMessage = messagesStatus.find(
              ({ onSiteMessageId }) =>
                onSiteMessageId === message.onSiteMessageId,
            );
            return foundMessage
              ? {
                  ...message,
                  status: foundMessage.status,
                }
              : message;
          }),
        }));
      }
    },
    onSettled: () =>
      v2OnSiteMessagesOn
        ? queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.ON_SITE_MESSAGES, contentType, contentId],
          })
        : queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.USER],
          }),
    ...options,
  });

  return {
    updateMessagesStatus,
    isUpdateMessagesMutationLoading,
    updateMessagesMutationError,
  };
}
