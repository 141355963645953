import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useElementQuery(slug, ageGroupId, options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: element,
    isLoading: isLoadingElement,
    isError: elementError,
  } = useQuery({
    queryKey: [QUERY_KEYS.ELEMENT, ageGroupId, slug],
    queryFn: () => client.getElement(slug),
    staleTime: oneHour * 4,
    ...options,
  });

  return { element, isLoadingElement, elementError };
}
