import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { Slot } from "@radix-ui/react-slot";

const Skeleton = React.forwardRef(function Skeleton(
  { loading = true, className, children, ...delegated },
  forwardedRef,
) {
  if (!loading) return children;

  const isInlineSkeleton = React.isValidElement(children);
  const Tag = isInlineSkeleton ? Slot : "span";

  return (
    <Tag
      ref={forwardedRef}
      className={twMerge(
        className,
        "animate-pulse bg-none bg-clip-border border-none shadow-none decoration-clone",
        "text-transparent outline-none pointer-events-none select-none cursor-default",
        "[&>*]:invisible [&::after]:invisible [&::before]:invisible",
        isInlineSkeleton && "leading-[0] font-[Arial,sans-serif]",
        "empty:block empty:min-h-[12px]",
      )}
      aria-hidden="true"
      tabIndex={-1}
      inert={true}
      {...delegated}
    >
      {children}
    </Tag>
  );
});

export default Skeleton;

Skeleton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};
