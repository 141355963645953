import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function usePrintableDetailsQuery({ slug }, options = {}) {
  const oneHour = 1000 * 60 * 60;

  const {
    data: printableDetails,
    isLoading: isPrintablesDetailsLoading,
    isError: printablesDetailsError,
  } = useQuery({
    queryKey: [QUERY_KEYS.PRINTABLE_DETAILS, slug],
    queryFn: () => client.getPrintablesDetails(slug),
    staleTime: oneHour * 4,
    ...options,
  });

  return {
    printableDetails,
    isPrintablesDetailsLoading,
    printablesDetailsError,
  };
}
