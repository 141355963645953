import { useQuery } from "@tanstack/react-query";
import { useParams } from "next/navigation";

import { QUERY_KEYS } from "../constants";
import api from "../api";
import useAgeGroup from "./useAgeGroup";

const client = api();

export default function useCompetency() {
  const params = useParams();
  const ageGroup = useAgeGroup();

  const { competency: competencyID } = params;

  const { data: competency } = useQuery({
    queryKey: [QUERY_KEYS.COMPETENCY, competencyID, ageGroup.id],
    queryFn: () => client.getCompetency(competencyID),
    enabled: !!competencyID,
  });

  return competency;
}
