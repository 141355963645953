import { useQuery } from "@tanstack/react-query";
import { compact } from "lodash";

import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useLayoutQuery(id, options = {}) {
  const oneHour = 1000 * 60 * 60;

  const {
    data: layout,
    isLoading: isLayoutLoading,
    error: layoutError,
  } = useQuery({
    queryKey: compact([QUERY_KEYS.LAYOUT, id]),
    queryFn: () => client.getLayout(id),
    staleTime: oneHour * 4,
    ...options,
  });

  return { layout, isLayoutLoading, layoutError };
}
