import { useQuery } from "@tanstack/react-query";

import api from "../api";
import { useUser } from "../contexts/user";
import { QUERY_KEYS } from "../constants";

const client = api();

export default function useOnSiteMessagesQuery(
  { contentType, contentId },
  options = {},
) {
  const { user } = useUser();
  const {
    data: onSiteMessages,
    isLoading: isOnSiteMessagesLoading,
    isError: onSiteMessagesError,
  } = useQuery({
    queryKey: [QUERY_KEYS.ON_SITE_MESSAGES, contentType, contentId],
    queryFn: () => client.getOnSiteMessagesV2(contentType, contentId),
    ...options,
  });

  if (user.features.v2OnSiteMessagesOn) {
    return {
      onSiteMessages,
      isOnSiteMessagesLoading,
      onSiteMessagesError,
    };
  }

  return {
    onSiteMessages: user.onSiteMessages,
    isOnSiteMessagesLoading: false,
    onSiteMessagesError: null,
  };
}
