/* eslint-disable react/jsx-no-constructed-context-values */
import React from "react";
import PropTypes from "prop-types";
import {
  useOverlay,
  DismissButton,
  FocusScope,
  OverlayContainer,
  useModal,
  useOverlayTrigger,
  useOverlayPosition,
  useDialog,
  useButton,
  useHover,
  mergeProps,
} from "react-aria";
import { AnimatePresence, motion } from "framer-motion";
import { useOverlayTriggerState } from "react-stately";
import { getInteractionModality } from "@react-aria/interactions";

const missingPopoverProvider = "Component must be used within <Popover.Root>";
const PopoverContext = React.createContext({
  get state() {
    throw new Error(missingPopoverProvider);
  },
  get restoreFocus() {
    throw new Error(missingPopoverProvider);
  },
  get triggerProps() {
    throw new Error(missingPopoverProvider);
  },
  get overlayProps() {
    throw new Error(missingPopoverProvider);
  },
  get triggerRef() {
    throw new Error(missingPopoverProvider);
  },
  get overlayRef() {
    throw new Error(missingPopoverProvider);
  },
  get shouldTriggerOnHover() {
    throw new Error(missingPopoverProvider);
  },
  get positionProps() {
    throw new Error(missingPopoverProvider);
  },
  get setPopperElement() {
    throw new Error(missingPopoverProvider);
  },
});
PopoverContext.displayName = "PopoverContext";

const usePopoverState = ({ isOpen, defaultOpen, onOpenChange }) => {
  const delay = 200;
  const delayTimeout = React.useRef();
  const { open, close, ...rest } = useOverlayTriggerState({
    isOpen,
    defaultOpen,
    onOpenChange,
  });

  // Overriding the open and close functions to add a debounce behavior.
  return {
    ...rest,
    open: (immediate = true) => {
      if (immediate) {
        open();
        clearTimeout(delayTimeout.current);
        delayTimeout.current = null;
      } else {
        clearTimeout(delayTimeout.current);
        delayTimeout.current = setTimeout(open, delay);
      }
    },
    close: (immediate = true) => {
      if (immediate) {
        close();
        clearTimeout(delayTimeout.current);
        delayTimeout.current = null;
      } else {
        clearTimeout(delayTimeout.current);
        delayTimeout.current = setTimeout(close, delay);
      }
    },
  };
};

const ContentBody = React.forwardRef(({ children, className }, forwardRef) => {
  const {
    overlayProps: defaultOverlayProps,
    positionProps,
    setPopperElement,
    restoreFocus,
    shouldTriggerOnHover,
    state,
  } = React.useContext(PopoverContext);
  // Hide content outside the modal from screen readers.
  const { modalProps } = useModal();
  // Without using this hook the content container dose not receive focus.
  const { dialogProps } = useDialog({}, forwardRef);

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const { overlayProps } = useOverlay(
    {
      onClose: state.close,
      isOpen: state.isOpen,
      isDismissable: true,
    },
    forwardRef,
  );

  const { hoverProps } = useHover({
    isDisabled: !shouldTriggerOnHover,
    onHoverStart: () => state.open(false),
    onHoverEnd: () => state.close(false),
  });

  return (
    <FocusScope restoreFocus={restoreFocus}>
      <motion.div
        {...mergeProps(
          overlayProps,
          dialogProps,
          defaultOverlayProps,
          positionProps,
          modalProps,
          hoverProps,
        )}
        ref={(node) => {
          // eslint-disable-next-line no-param-reassign
          forwardRef.current = node;
          setPopperElement(node);
        }}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { duration: 0.2, ease: "easeOut" },
        }}
        exit={{
          opacity: 0,
          transition: { duration: 0.15, ease: "easeIn" },
        }}
        className={className}
      >
        {children}
        <DismissButton onDismiss={state.close} />
      </motion.div>
    </FocusScope>
  );
});

export function Content({ children, className }) {
  const { state, overlayRef } = React.useContext(PopoverContext);
  return (
    <AnimatePresence>
      {state.isOpen && (
        <OverlayContainer>
          <ContentBody className={className} ref={overlayRef}>
            {children}
          </ContentBody>
        </OverlayContainer>
      )}
    </AnimatePresence>
  );
}

export const Trigger = React.forwardRef(function Trigger(
  { children, disabled = false, ariaLabel, className },
  forwardedRef,
) {
  const {
    triggerProps,
    triggerRef,
    shouldTriggerOnHover,
    state,
  } = React.useContext(PopoverContext);
  const { buttonProps } = useButton(
    {
      ...triggerProps,
      elementType: "div",
      isDisabled: disabled,
      "aria-label": ariaLabel,
    },
    triggerRef,
  );

  const { hoverProps } = useHover({
    isDisabled: !shouldTriggerOnHover || disabled,
    onHoverStart: () => {
      if (getInteractionModality() === "pointer") {
        state.open(false);
      }
    },
    onHoverEnd: () => {
      state.close(false);
    },
  });

  return (
    <div
      {...mergeProps(buttonProps, hoverProps)}
      ref={(node) => {
        triggerRef.current = node;

        if (forwardedRef) {
          if (typeof forwardedRef === "function") {
            forwardedRef(node);
          } else {
            // eslint-disable-next-line no-param-reassign
            forwardedRef.current = node;
          }
        }
      }}
      className={className}
    >
      {children}
    </div>
  );
});

export function Root({
  children,
  isOpen = false,
  placement = "bottom",
  offset = 0,
  crossOffset = 0,
  shouldFlip = true,
  restoreFocus = true,
  shouldTriggerOnHover = false,
  maxHeight,
  onOpen = () => {},
  onClose = () => {},
}) {
  const state = usePopoverState({
    isOpen,
    onOpenChange: (open) => (open ? onOpen() : onClose()),
  });
  const triggerRef = React.useRef();
  const overlayRef = React.useRef();
  const [, setPopperElement] = React.useState(null);

  // Get props for the trigger and overlay. This also handles
  // hiding the overlay when a parent element of the trigger scrolls
  // (which invalidates the popover positioning).
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: "menu" },
    state,
    triggerRef,
  );

  // Without using this hook when a parent element of the trigger scrolls the hiding dose not work.
  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement,
    shouldFlip,
    containerPadding: 0,
    isOpen: state.isOpen,
    offset,
    crossOffset,
    maxHeight,
  });

  return (
    <PopoverContext.Provider
      value={{
        overlayRef,
        triggerRef,
        overlayProps,
        triggerProps,
        setPopperElement,
        positionProps,
        restoreFocus,
        shouldTriggerOnHover,
        state,
      }}
    >
      {children}
    </PopoverContext.Provider>
  );
}

Root.propTypes = {
  isOpen: PropTypes.bool,
  placement: PropTypes.oneOf(["top", "bottom", "bottom start", "bottom end"]),
  offset: PropTypes.number,
  crossOffset: PropTypes.number,
  shouldFlip: PropTypes.bool,
  restoreFocus: PropTypes.bool,
  shouldTriggerOnHover: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  maxHeight: PropTypes.number,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

Trigger.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
};

ContentBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};
Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};
