import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useContentTagPrintablesQuery(slug, options = {}) {
  const oneHour = 1000 * 60 * 60;

  const {
    data: printables,
    isLoading: isPrintablesLoading,
    isError: printablesError,
  } = useQuery({
    queryKey: [QUERY_KEYS.CONTENT_TAG_PRINTABLE_LIST, slug],
    queryFn: () => client.getContentTagPrintables(slug),
    staleTime: oneHour * 4,
    ...options,
  });

  return {
    printables,
    isPrintablesLoading,
    printablesError,
  };
}
