import React from "react";
import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import { QUERY_KEYS } from "../../constants";
import useChampionsQuery from "../useChampionsQuery";
import useProfile from "../useProfile";

const fetcher = api();

export default function useProfileChampionsQuery(options = {}) {
  const { profile } = useProfile();
  const {
    champions: rawChampions,
    isChampionsLoading: isRawChampionsLoading,
    championsError: rawChampionsError,
  } = useChampionsQuery();
  const {
    data: championsProgressions = [],
    isLoading: isChampionsProgressionsLoading,
    isError: championsProgressionsError,
  } = useQuery({
    queryKey: [QUERY_KEYS.PROFILE_CHAMPIONS],
    queryFn: fetcher.getProfileChampions,
    ...options,
  });

  const isChampionsLoading =
    isRawChampionsLoading || isChampionsProgressionsLoading;
  const championsError = rawChampionsError || championsProgressionsError;
  const currentChampionId = profile?.currentClassroomChamp?.champId;

  const getChampionImages = (championProgression, champion) => {
    const source = championProgression?.images || champion.images.phase1;
    return {
      headshot: {
        "regular@1x": source.headshot?.["1x"] || null,
        "regular@2x": source.headshot?.["2x"] || null,
        "regular@3x": source.headshot?.["3x"] || null,
      },
      full: {
        "regular@1x": source.full?.["1x"] || null,
        "regular@2x": source.full?.["2x"] || null,
        "regular@3x": source.full?.["3x"] || null,
      },
    };
  };

  const profileChampions = React.useMemo(() => {
    if (isChampionsLoading || championsError) return [];

    return rawChampions.map((champion) => {
      const championProgression = championsProgressions.find(
        ({ champId }) => champId === champion.id,
      );

      return {
        ...champion,
        level: championProgression?.level || 0,
        levelPercentComplete: championProgression?.levelPercentComplete || 0,
        levelUpCertificate2: championProgression?.levelUpCertificate2 || null,
        levelUpCertificate3: championProgression?.levelUpCertificate3 || null,
        levelUpCertificate4: championProgression?.levelUpCertificate4 || null,
        isGraduated: championProgression?.graduated || false,
        graduationCertificate:
          championProgression?.graduationCertificate || null,
        isCurrent: currentChampionId
          ? currentChampionId === championProgression?.champId
          : false,
        currentLevelImages: getChampionImages(championProgression, champion),
      };
    });
  }, [
    isChampionsLoading,
    championsError,
    rawChampions,
    championsProgressions,
    currentChampionId,
  ]);

  return {
    profileChampions,
    isProfileChampionsLoading: isChampionsLoading,
    profileChampionsError: championsError,
  };
}
