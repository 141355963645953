import React from "react";
import PropTypes from "prop-types";
import { useComposedRefs, useInView } from "@gonoodle/gn-universe-utils";

import { ImageSourcesType } from "../Image/types";
import Image from "../Image";

const BackgroundVideo = React.forwardRef(
  ({ source, fallbackImage, ...rest }, forwardedRef) => {
    const [canRenderFallback, setCanRenderFallback] = React.useState(!source);
    const videoRef = React.useRef(null);
    const { ref: inViewRef, inView } = useInView();
    const composedRefs = useComposedRefs(videoRef, inViewRef, forwardedRef);

    React.useEffect(() => {
      if (videoRef.current) {
        if (inView) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    }, [videoRef, inView]);

    if (canRenderFallback && fallbackImage) {
      return (
        <Image
          sources={fallbackImage.sources}
          alt={fallbackImage.alt}
          {...rest}
        />
      );
    }

    return (
      <video
        ref={composedRefs}
        src={source}
        autoPlay
        loop
        playsInline
        muted
        onError={() => setCanRenderFallback(true)}
        {...rest}
        data-testid="test-background-video"
      />
    );
  },
);

BackgroundVideo.propTypes = {
  source: PropTypes.string,
  fallbackImage: PropTypes.shape({
    sources: ImageSourcesType.isRequired,
    alt: PropTypes.string,
  }),
};

export default BackgroundVideo;
