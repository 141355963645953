import React from "react";
import PropTypes from "prop-types";

import { ImageSourcesType } from "../Image/types";
import Image from "../Image";

const ImageTile = React.forwardRef(
  (
    {
      imageClassName = "",
      format = "portrait",
      title,
      onClick,
      href,
      sources = {},
      name,
      description,
      alt = "",
      childComponent = false,
    },
    ref,
  ) => {
    const hasSources = sources && Object.values(sources).length;
    const formatClasses = () => {
      let classes = "";
      switch (format) {
        case "portrait":
          classes = "aspect-w-7 aspect-h-10";
          break;
        case "square":
          classes = "aspect-w-1 aspect-h-1";
          break;
        case "landscape":
          classes = "aspect-w-16 aspect-h-9";
          break;
        default:
          break;
      }
      return classes;
    };
    return (
      <div
        data-testid="image-tile"
        ref={ref}
        onKeyUp={onClick}
        onClick={onClick}
        href={href}
        tabIndex={0}
        role="link"
        className="h-full block group cursor-pointer"
      >
        <div className={`overflow-hidden rounded ${formatClasses()}`}>
          {hasSources ? (
            <Image
              className={`object-cover transform transition-transform group-hover:scale-110 filter ${
                !childComponent ? "drop-shadow rounded" : ""
              } ${imageClassName}`}
              alt={alt}
              sources={sources}
            />
          ) : (
            <div className="flex justify-center justify-items-center object-cover rounded text-center p-sm bg-gradient-to-tr from-gray-200 to-white hover:from-white transform transition-transform group-hover:scale-105 filter drop-shadow">
              <p
                className="gn-headline-lg text-gray-900 break-words group-hover:text-purple my-auto"
                style={{ WebkitHyphens: `auto`, hyphens: `auto` }}
              >
                {name}
              </p>
            </div>
          )}
        </div>
        {title ? (
          <h3 className="gn-text-sm mt-sm text-white line-clamp-3">{title}</h3>
        ) : null}
        {description ? (
          <p className="gn-text-xs mt-xs text-gray-500 line-clamp-4">
            {description}
          </p>
        ) : null}
      </div>
    );
  },
);

ImageTile.propTypes = {
  imageClassName: PropTypes.string,
  format: PropTypes.oneOf(["portrait", "square", "landscape"]),
  title: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  sources: ImageSourcesType,
  alt: PropTypes.string,
  childComponent: PropTypes.bool,
};

export default ImageTile;
