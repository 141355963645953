import { useQueryClient, useMutation } from "@tanstack/react-query";

import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useSurveyMutation(
  { slug, ageGroupId, survey },
  options = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess = () => {}, ...delegated } = options;

  const {
    mutateAsync: completeSurveyAsync,
    isPending: isSurveyMutationLoading,
    isError: surveyMutationError,
  } = useMutation({
    mutationFn: () =>
      client.completeSurvey({
        ageGroupId,
        survey,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ELEMENT, slug],
      });
      return onSuccess();
    },
    ...delegated,
  });

  return {
    completeSurveyAsync,
    isSurveyMutationLoading,
    surveyMutationError,
  };
}
