/* eslint-disable import/no-relative-packages */
import React from "react";
import {
  QueryClient,
  QueryClientProvider,
  HydrationBoundary,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { find } from "lodash";
import { useRouter } from "next/router";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { usePrevious } from "@gonoodle/gn-universe-utils";
import {
  AssetsConfigProvider,
  OverlayProvider,
  Alert,
} from "@gonoodle/gn-universe-ui";
import { Montserrat } from "next/font/google";
import localFont from "next/font/local";

import AppConfigProvider from "../contexts/appConfig";
import AnalyticsProvider from "../contexts/Analytics";
import OneTrustProvider, * as OneTrust from "../contexts/OneTrust";
import UserProvider, { useUser } from "../contexts/user";
import BeaconProvider from "../contexts/Beacon";
import PianoProvider from "../contexts/Piano";
import TransmogrifierProvider from "../contexts/Transmogrifier";
import SplashScreen from "../components/SplashScreen";
import { USER_TYPES, URLS, ELEMENT_IDS } from "../constants";
import BugsnagClient from "../utils/bugsnag";
import config from "../config";
import ReferrerProvider from "../contexts/Referrer";

import "../../../assets/styles/tailwind.css";
import "../../../assets/styles/vars.css";
import "../../../assets/styles/utils.css";

const segment = new AnalyticsBrowser();
const ErrorBoundary =
  BugsnagClient.getPlugin("react").createErrorBoundary(React);
const montserrat = Montserrat({
  subsets: ["latin"],
  variable: "--font-montserrat",
});
const degular = localFont({
  src: "../../../assets/fonts/degular.woff2",
  style: "normal",
  weight: "800",
  variable: "--font-degular",
});
const degularDisplay = localFont({
  src: "../../../assets/fonts/degular-display.woff2",
  style: "italic",
  weight: "800",
  variable: "--font-degular-display",
});

export default function GoNoodle({ Component, pageProps }) {
  const [queryClient] = React.useState(() => new QueryClient());
  const [navbarPosition, setNavbarPosition] = React.useState(null); // Null if no navbar
  const assetsConfig = {
    assetsUrl: URLS.GN_ASSETS_BASE_URL,
  };

  /**
   * Adding font-sans Tailwind util to the main element to ensure that the font is applied to the entire app as a default.
   * The values for the font-body class are defined in the tailwind.config.js file under the fontFamily.sans property.
   */
  React.useEffect(() => {
    // Adding fonts to the document body to ensure that they can be used inside portals.
    document.body.classList.add(
      montserrat.variable,
      degular.variable,
      degularDisplay.variable,
      "font-body",
    );
  }, []);

  React.useEffect(() => {
    const updateNavbarPosition = () => {
      const navbar = document.getElementById(ELEMENT_IDS.NAVBAR);
      if (navbar) {
        setNavbarPosition(navbar.getBoundingClientRect().bottom);
      } else {
        setNavbarPosition(null); // No navbar present
      }
    };

    updateNavbarPosition();

    window.addEventListener("resize", updateNavbarPosition);

    const navbar = document.getElementById(ELEMENT_IDS.NAVBAR);
    const resizeObserver = navbar
      ? new ResizeObserver(updateNavbarPosition)
      : null;
    if (resizeObserver) resizeObserver.observe(navbar);

    return () => {
      window.removeEventListener("resize", updateNavbarPosition);
      if (resizeObserver) resizeObserver.disconnect();
    };
  }, []);

  return (
    <ErrorBoundary>
      <GoogleReCaptchaProvider
        reCaptchaKey={config.RECAPTCHA_ENTERPRISE_KEY}
        scriptProps={{
          async: true,
          useEnterprise: true,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <HydrationBoundary state={pageProps.dehydratedState}>
            <OneTrustProvider>
              <ReferrerProvider>
                <AppConfigProvider>
                  <UserProvider>
                    <PianoProvider>
                      <AnalyticsProvider>
                        <BootstrapClientAnalytics />
                        <OverlayProvider>
                          <BeaconProvider>
                            <AssetsConfigProvider value={assetsConfig}>
                              <SplashScreen>
                                <Alert.Provider
                                  offset={
                                    navbarPosition !== 0
                                      ? navbarPosition + 10
                                      : 20
                                  }
                                >
                                  <TransmogrifierProvider>
                                    <Component {...pageProps} />
                                  </TransmogrifierProvider>
                                </Alert.Provider>
                              </SplashScreen>
                            </AssetsConfigProvider>
                          </BeaconProvider>
                        </OverlayProvider>
                      </AnalyticsProvider>
                    </PianoProvider>
                  </UserProvider>
                </AppConfigProvider>
              </ReferrerProvider>
            </OneTrustProvider>
          </HydrationBoundary>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
    </ErrorBoundary>
  );
}

const BootstrapClientAnalytics = () => {
  const router = useRouter();
  const { user, updateUser } = useUser();
  const performanceConsentStatus = OneTrust.useConsentStatus(
    OneTrust.PERFORMANCE_SDKS,
  );
  const prevPerformanceConsentStatus = usePrevious(performanceConsentStatus);

  React.useEffect(() => {
    if (performanceConsentStatus === OneTrust.CONSENT_GIVEN) {
      segment
        .load(
          {
            writeKey: process.env.NEXT_PUBLIC_ANALYTICS_API_KEY,
          },
          { initialPageview: true },
        )
        .catch((err) => BugsnagClient.notify(err));
    }
  }, [performanceConsentStatus]);

  React.useEffect(() => {
    if (
      prevPerformanceConsentStatus === OneTrust.CONSENT_GIVEN &&
      performanceConsentStatus === OneTrust.CONSENT_NOT_GIVEN
    ) {
      segment?.reset();
      window.location.reload();
    }
  }, [performanceConsentStatus, prevPerformanceConsentStatus]);

  React.useEffect(() => {
    const handleRouteChange = (_, { shallow }) => {
      if (shallow === false) {
        segment.page({
          referrer: "", // disable referrer tracking for privacy
        });
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  React.useEffect(() => {
    if (user?.analyticsId && user?.userTypeId) {
      segment.ready(() => {
        segment.identify(user?.analyticsId, {
          user_type: find(USER_TYPES, { id: user.userTypeId })?.name,
        });
      });
    }
  }, [user?.analyticsId, user?.userTypeId]);

  React.useEffect(() => {
    if (
      user?.allowTracking === false &&
      performanceConsentStatus === OneTrust.CONSENT_GIVEN
    ) {
      updateUser({ allowTracking: true });
    } else if (
      user?.allowTracking === true &&
      performanceConsentStatus === OneTrust.CONSENT_NOT_GIVEN
    ) {
      updateUser({ allowTracking: false });
    }
  }, [user?.allowTracking, updateUser, performanceConsentStatus]);

  return null;
};
