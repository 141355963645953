export default {
  AD_PRODUCTS: "adProducts",
  CONTENT_TAG: "contentTag",
  CONTENT_TAG_LIST: "contentTagList",
  VIDEO_DETAILS: "videoDetails",
  GAME_DETAILS: "gameDetails",
  GAME_STATE: "gameState",
  CONTENT_TAG_PRINTABLE_LIST: "contentTagPrintableList",
  CONTENT_TAG_VIDEO_LIST: "contentTagVideoList",
  CONTENT_TAG_BLOG_LIST: "contentTagBlogList",
  CONTENT_TAG_GAME_LIST: "contentTagGameList",
  PRINTABLE_DETAILS: "printableDetails",
  RECENTLY_PUBLISHED_VIDEOS: "recentlyPublishedVideos",
  RECOMMENDED_VIDEOS: "recommendedVideos",
  HERO_VIDEO: "heroVideo",
  HERO_UNIT: "heroUnit",
  NEWEST_PRINTABLES: "newestPrintables",
  USER: "user",
  USER_ACCESSES: "userAccesses",
  FEATURED_CONTENT: "featuredContent",
  SEARCH_FILTERS: "searchFilters",
  AD_PRODUCT_PRE_ROLL_SLATE: "adProductPreRollSlate",
  SHELVES: "shelf",
  GRADES: "grades",
  SCHOOLS: "schools",
  LOGIN: "login",
  FAVORITES: "favorites",
  CHAMPIONS: "champions",
  PROFILE_CHAMPIONS: "profileChampions",
  CURRICULUM: "curriculum",
  CURRICULUMS: "curriculums",
  COMPETENCY: "competency",
  ELEMENT: "element",
  LAYOUT: "layout",
  FALLBACK_LAYOUT: "fallbackLayout",
  ON_SITE_MESSAGES: "onSiteMessages",
  COMPETENCIES_COMPLETIONS: "competenciesCompletions",
  BADGES: "badges",
};
