/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-constructed-context-values */
import React from "react";
import PropTypes from "prop-types";
import {
  VisuallyHidden,
  mergeProps,
  useFocusRing,
  useNumberFormatter,
  useSlider,
  useSliderThumb,
  useHover,
} from "react-aria";
import { useSliderState } from "react-stately";

import { useSlot } from "../../hooks";

const missingSliderProvider = "Component must be used within <Slider.Root>";
const SliderContext = React.createContext({
  get state() {
    throw new Error(missingSliderProvider);
  },
  get trackProps() {
    throw new Error(missingSliderProvider);
  },
  get labelProps() {
    throw new Error(missingSliderProvider);
  },
  get outputProps() {
    throw new Error(missingSliderProvider);
  },
  get trackRef() {
    throw new Error(missingSliderProvider);
  },
  get labelRef() {
    throw new Error(missingSliderProvider);
  },
});
SliderContext.displayName = "SliderContext";

const missingSliderThumbProvider =
  "Component must be used within <Slider.Thumb>";
const SliderThumbContext = React.createContext({
  get labelRef() {
    throw new Error(missingSliderThumbProvider);
  },
  get labelProps() {
    throw new Error(missingSliderThumbProvider);
  },
});
SliderThumbContext.displayName = "SliderThumbContext";

export function Root({
  value,
  defaultValue,
  step = 1,
  formatOptions,
  maxValue = 100,
  minValue = 0,
  disabled = false,
  children,
  className,
  onChange,
}) {
  const trackRef = React.useRef(null);
  const [labelRef, label] = useSlot();
  const numberFormatter = useNumberFormatter(formatOptions);
  const state = useSliderState({
    value,
    defaultValue,
    step,
    maxValue,
    minValue,
    isDisabled: disabled,
    onChange,
    numberFormatter,
  });

  const { groupProps, trackProps, labelProps, outputProps } = useSlider(
    {
      value,
      defaultValue,
      step,
      maxValue,
      minValue,
      isDisabled: disabled,
      trackRef,
      label,
      onChange,
    },
    state,
    trackRef,
  );

  return (
    <SliderContext.Provider
      value={{
        state,
        trackProps,
        labelProps,
        outputProps,
        trackRef,
        labelRef,
      }}
    >
      <div className={className} {...groupProps}>
        {children}
      </div>
    </SliderContext.Provider>
  );
}

export function Label({ className, children }) {
  const { labelProps, labelRef } = React.useContext(SliderContext);

  return (
    <label ref={labelRef} {...labelProps} className={className}>
      {children}
    </label>
  );
}

export function Output({ className, children, thumbValue = 0 }) {
  const { state, outputProps } = React.useContext(SliderContext);
  const computedChildren =
    typeof children === "function"
      ? children({ state })
      : children || state.getThumbValueLabel(thumbValue);

  return (
    <output className={className} {...outputProps}>
      {computedChildren}
    </output>
  );
}

export function Track({ className, children }) {
  const { state, trackProps, trackRef } = React.useContext(SliderContext);
  const computedChildren =
    typeof children === "function" ? children({ state }) : children;

  return (
    <div ref={trackRef} className={className} {...trackProps}>
      {computedChildren}
    </div>
  );
}

export function Thumb({ index = 0, name, className, children, ariaLabel }) {
  const inputRef = React.useRef(null);
  const { state, trackRef } = React.useContext(SliderContext);
  const { focusProps, isFocusVisible } = useFocusRing();

  const [labelRef, label] = useSlot();
  const {
    thumbProps,
    inputProps,
    labelProps,
    isDisabled,
    isFocused,
    isDragging,
  } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef,
      label,
      "aria-label": ariaLabel,
    },
    state,
  );

  const { hoverProps, isHovered } = useHover({ isDisabled });

  return (
    <div
      {...mergeProps(thumbProps, hoverProps)}
      className={className}
      data-hovered={isHovered || undefined}
      data-dragging={isDragging || undefined}
      data-focused={isFocused || undefined}
      data-focus-visible={isFocusVisible || undefined}
      data-disabled={isDisabled || undefined}
    >
      <VisuallyHidden>
        <input
          ref={inputRef}
          name={name}
          {...mergeProps(inputProps, focusProps)}
        />
      </VisuallyHidden>

      <SliderThumbContext.Provider value={{ labelRef, labelProps }}>
        {children}
      </SliderThumbContext.Provider>
    </div>
  );
}

function ThumbLabel({ className, children }) {
  const { labelProps, labelRef } = React.useContext(SliderThumbContext);

  return (
    <label ref={labelRef} {...labelProps} className={className}>
      {children}
    </label>
  );
}

Thumb.Label = ThumbLabel;

Root.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  step: PropTypes.number,
  formatOptions: PropTypes.object,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Output.propTypes = {
  thumbValue: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Track.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

Thumb.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ThumbLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
