import React from "react";
import isEqual from "lodash/isEqual";

export default function useDeepCompareEffect(callback, dependencies) {
  const previousDeps = React.useRef(dependencies);

  if (!Array.isArray(dependencies)) {
    throw new Error(
      "useDeepCompareEffect should be used with an array of dependencies",
    );
  }

  if (!isEqual(previousDeps.current, dependencies)) {
    previousDeps.current = dependencies;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(callback, previousDeps.current);
}
