import React from "react";
import PropTypes from "prop-types";

function TermsOfServiceLink({ className }) {
  return (
    <a
      href="https://www.gonoodle.com/company/p/tos"
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      Terms of Service
    </a>
  );
}

TermsOfServiceLink.propTypes = {
  className: PropTypes.string,
};

export default TermsOfServiceLink;
