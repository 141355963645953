import React from "react";
import PropTypes from "prop-types";
import { useDialog } from "react-aria";

import Modal from "../Modal";

const missingDialogProvider = "Component must be used within <Dialog.Root>";
const DialogContext = React.createContext({
  get open() {
    throw new Error(missingDialogProvider);
  },
});
DialogContext.displayName = "DialogContext";

export function Root({
  children,
  className,
  isOpen = false,
  isDismissable = true,
  onClose = () => {},
}) {
  const ref = React.useRef(null);
  const { dialogProps, titleProps } = useDialog({ role: "dialog" }, ref);
  const value = React.useMemo(
    () => ({
      titleProps,
    }),
    [titleProps],
  );
  return (
    <Modal
      className={className}
      isOpen={isOpen}
      isDismissable={isDismissable}
      overlay={dialogProps}
      onClose={onClose}
      ref={ref}
    >
      <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
    </Modal>
  );
}

export function Content({ children, className }) {
  return <div className={className}>{children}</div>;
}

export function Title({ children, className }) {
  const { titleProps } = React.useContext(DialogContext);
  return (
    <h2 {...titleProps} className={className}>
      {children}
    </h2>
  );
}

Root.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  isDismissable: PropTypes.bool,
  onClose: PropTypes.func,
};

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};
