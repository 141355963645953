import React from "react";
import PropTypes from "prop-types";
import { useButton } from "react-aria";
import { useComposedRefs } from "@gonoodle/gn-universe-utils";

const Pressable = React.forwardRef(function Pressable(
  {
    children,
    disabled = false,
    className,
    elementType = "button",
    href,
    target,
    rel,
    buttonType,
    ariaLabel,
    testId,
    onPress,
    ...delegated
  },
  forwardedRef,
) {
  const ref = React.useRef();
  const Wrapper = elementType;
  const { buttonProps } = useButton(
    {
      isDisabled: disabled,
      elementType,
      href,
      target,
      rel,
      type: buttonType,
      "aria-label": ariaLabel,
      onPress,
      ...delegated,
    },
    ref,
  );
  const composedRefs = useComposedRefs(ref, forwardedRef);
  return (
    <Wrapper
      className={`touch-none 
      ${buttonProps.disabled && "cursor-not-allowed"} ${className}`}
      {...buttonProps}
      ref={composedRefs}
      data-testid={testId}
    >
      {children}
    </Wrapper>
  );
});

Pressable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  elementType: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  buttonType: PropTypes.oneOf(["button", "submit"]),
  ariaLabel: PropTypes.string,
  testId: PropTypes.string,
  onPress: PropTypes.func,
};

export default Pressable;
