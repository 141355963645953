import { useMutation } from "@tanstack/react-query";

import api from "../api";

const client = api();

export default function useForgotPassword() {
  const {
    mutate: forgotPassword,
    isPending: isLoading,
    isSuccess,
    error,
  } = useMutation({
    mutationFn: (email) => client.forgotPassword(email),
  });

  return { forgotPassword, isLoading, isSuccess, error };
}
