import { useMutation } from "@tanstack/react-query";
import { useSearchParams } from "next/navigation";

import api from "../api";

const client = api();

export default function useResetPassword() {
  const searchParams = useSearchParams();
  const resetToken = searchParams.get("reset_token");
  const {
    mutate: resetPassword,
    isPending: isLoading,
    isSuccess,
    error,
  } = useMutation({
    mutationFn: (password) => client.resetPassword(password, resetToken),
  });

  return { resetPassword, isLoading, isSuccess, error };
}
