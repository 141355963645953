import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useCurriculumsQuery(options = {}) {
  const oneHour = 1000 * 60 * 60;

  const {
    data: curriculums,
    isLoading: isCurriculumsLoading,
    isError: curriculumsError,
  } = useQuery({
    queryKey: [QUERY_KEYS.CURRICULUMS],
    queryFn: client.getCurriculums,
    staleTime: oneHour * 4,
    ...options,
  });

  return { curriculums, isCurriculumsLoading, curriculumsError };
}
