import { useQueryClient, useMutation } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../constants";
import api from "../../api";

const fetcher = api();

export default function useClaimBadgeMutation(options = {}) {
  const queryClient = useQueryClient();
  const { onSuccess = () => {}, ...delegated } = options;

  const {
    mutate: claimBadge,
    isPending: isClaimBadgeLoading,
    isError: isClaimBadgeError,
    reset: resetClaimBadge,
  } = useMutation({
    mutationFn: ({ id }) => fetcher.claimBadge(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER],
      });
      onSuccess();
    },
    ...delegated,
  });

  return {
    claimBadge,
    isClaimBadgeLoading,
    isClaimBadgeError,
    resetClaimBadge,
  };
}
