import React from "react";
import PropTypes from "prop-types";
import * as Select from "@radix-ui/react-select";

export function Content({
  position = "popper",
  side = "bottom",
  align = "start",
  sideOffset = 0,
  alignOffset = 0,
  className,
  children,
}) {
  return (
    <Select.Portal>
      <Select.Content
        position={position}
        side={side}
        sideOffset={sideOffset}
        align={align}
        alignOffset={alignOffset}
        className={className}
        style={{
          minWidth: "var(--radix-select-trigger-width)",
          maxHeight: "var(--radix-select-content-available-height)",
        }}
      >
        {children}
      </Select.Content>
    </Select.Portal>
  );
}

Content.propTypes = {
  position: PropTypes.oneOf(["item-aligned", "popper"]),
  side: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  sideOffset: PropTypes.number,
  align: PropTypes.oneOf(["start", "center", "end"]),
  alignOffset: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export {
  Root,
  Trigger,
  Value,
  Icon,
  Viewport,
  Item,
  ItemText,
  Separator,
} from "@radix-ui/react-select";
