"use client";

import React from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { usePathname } from "next/navigation";

import { QUERY_KEYS, USER_TYPES } from "../../constants";
import api from "../../api";
import BugsnagClient from "../../utils/bugsnag";
import useRouter from "../../hooks/useRouter";

const client = api();
const missingUserProvider = "You forgot to wrap your app in <UserProvider>";
const USER_SYNC_KEY = "synchronizeUser";

export const UserContext = React.createContext({
  get user() {
    throw new Error(missingUserProvider);
  },
  get error() {
    throw new Error(missingUserProvider);
  },
  get isLoading() {
    throw new Error(missingUserProvider);
  },
});
UserContext.displayName = "UserContext";

export const useUser = () => React.useContext(UserContext);

const Provider = ({ children }) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const pathname = usePathname();

  const {
    data: user,
    isLoading: isGettingUser,
    isSuccess,
  } = useQuery({
    queryKey: [QUERY_KEYS.USER],
    queryFn: client.getUser,
    select: (undecoratedUser) => ({
      ...undecoratedUser,
      isAnonymous:
        undecoratedUser.userTypeId === USER_TYPES.ANONYMOUS.id ||
        undecoratedUser.userTypeId === USER_TYPES.ANONYMOUS_UNIVERSE.id,
      isFamily: undecoratedUser.userTypeId === USER_TYPES.FAMILY.id,
      isUnregisteredFamily:
        undecoratedUser.userTypeId === USER_TYPES.UNREGISTERED_FAMILY.id,
      isUnregisteredTeacher:
        undecoratedUser.userTypeId === USER_TYPES.UNREGISTERED_TEACHER.id,
      isClassroomTeacher:
        undecoratedUser.userTypeId === USER_TYPES.CLASSROOM_TEACHER.id,
      isLoggedIn: [
        USER_TYPES.FAMILY.id,
        USER_TYPES.CLASSROOM_TEACHER.id,
      ].includes(undecoratedUser.userTypeId),
    }),
  });

  React.useEffect(() => {
    if (isSuccess && user?.email && user?.id) {
      BugsnagClient.setUser(user.id, user.email);
    }
  }, [isSuccess, user?.email, user?.id]);

  const {
    mutate: updateUser,
    isPending: isUpdatingUser,
    error: updateError,
  } = useMutation({
    mutationFn: (newUserProperties) => client.updateUser(newUserProperties),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER],
      });
    },
  });

  const { mutate: updateUserFlags } = useMutation({
    mutationFn: (newUserFlags) => client.updateUserFlags(newUserFlags),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER],
      });
    },
  });

  const handleUserSyncStorageEvent = React.useCallback(
    ({ key, newValue }) => {
      if (key === USER_SYNC_KEY && newValue !== null) {
        router.replace(pathname);

        localStorage.removeItem(USER_SYNC_KEY);
      }
    },
    [router, pathname],
  );

  const { mutate: logout, isPending: isLoggingOut } = useMutation({
    mutationFn: client.logout,
    onSuccess: async () => {
      await queryClient.fetchQuery({
        queryKey: [QUERY_KEYS.USER],
        queryFn: client.createUser,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USER_ACCESSES],
      });

      // This will trigger a refresh of the user in the other tabs
      localStorage.setItem(USER_SYNC_KEY, Date.now().toString());

      // This will trigger a refresh of the user in this tab
      handleUserSyncStorageEvent({
        key: USER_SYNC_KEY,
        newValue: Date.now(),
      });
    },
  });

  const isLoading = [isGettingUser, isUpdatingUser, isLoggingOut].includes(
    true,
  );

  React.useEffect(() => {
    window.addEventListener("storage", handleUserSyncStorageEvent);

    return () => {
      window.removeEventListener("storage", handleUserSyncStorageEvent);
    };
  }, [handleUserSyncStorageEvent]);

  return (
    <UserContext.Provider
      value={{
        user,
        isLoading,
        errors: { updateError },
        updateUser,
        updateUserFlags,
        logout,
      }}
    >
      {user ? children : null}
    </UserContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Provider;
