import Joi from "joi";

import { PAGES } from "../constants";

export const newsletterSignUp = Joi.object({
  sourcePage: Joi.string()
    .valid(PAGES.HOME, PAGES.NEWSLETTER_SIGNUP)
    .required(),
  utmSource: Joi.string(),
  utmMedium: Joi.string(),
  utmCampaign: Joi.string(),
});
