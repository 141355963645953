import React from "react";
import PropTypes from "prop-types";

import { ImageSourcesType } from "./types";

function Image({ sources = {}, alt = "", className }) {
  return (
    <img
      className={className}
      alt={alt}
      src={sources?.["regular@1x"]}
      srcSet={`${sources?.["regular@2x"]} 2x,
            ${sources?.["regular@3x"]} 3x,`}
      draggable={false}
    />
  );
}

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  sources: ImageSourcesType,
};

export default Image;
